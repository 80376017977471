module.exports = {
	"$schema": "vscode://schemas/color-theme",
	"type": "dark",
	"colors": {
		"activityBar.activeBorder": "#f9826c",
		"activityBar.background": "#24292e",
		"activityBar.border": "#1b1f23",
		"activityBar.foreground": "#e1e4e8",
		"activityBar.inactiveForeground": "#6a737d",
		"activityBarBadge.background": "#0366d6",
		"activityBarBadge.foreground": "#ffffff",
		"badge.background": "#044289",
		"badge.foreground": "#c8e1ff",
		"breadcrumb.activeSelectionForeground": "#d1d5da",
		"breadcrumb.focusForeground": "#e1e4e8",
		"breadcrumb.foreground": "#959da5",
		"breadcrumbPicker.background": "#2b3036",
		"button.background": "#176f2c",
		"button.foreground": "#dcffe4",
		"button.hoverBackground": "#22863a",
		"button.secondaryBackground": "#444d56",
		"button.secondaryForeground": "#ffffff",
		"button.secondaryHoverBackground": "#586069",
		"checkbox.background": "#444d56",
		"checkbox.border": "#1b1f23",
		"debugToolBar.background": "#2b3036",
		"descriptionForeground": "#959da5",
		"diffEditor.insertedTextBackground": "#28a74530",
		"diffEditor.removedTextBackground": "#d73a4930",
		"dropdown.background": "#2f363d",
		"dropdown.border": "#1b1f23",
		"dropdown.foreground": "#e1e4e8",
		"dropdown.listBackground": "#24292e",
		"editor.background": "#24292e",
		"editor.findMatchBackground": "#ffd33d44",
		"editor.findMatchHighlightBackground": "#ffd33d22",
		"editor.focusedStackFrameHighlightBackground": "#2b6a3033",
		"editor.foldBackground": "#58606915",
		"editor.foreground": "#e1e4e8",
		"editor.inactiveSelectionBackground": "#3392ff22",
		"editor.lineHighlightBackground": "#2b3036",
		"editor.linkedEditingBackground": "#3392ff22",
		"editor.selectionBackground": "#3392ff44",
		"editor.selectionHighlightBackground": "#17e5e633",
		"editor.selectionHighlightBorder": "#17e5e600",
		"editor.stackFrameHighlightBackground": "#c6902625",
		"editor.wordHighlightBackground": "#17e5e600",
		"editor.wordHighlightBorder": "#17e5e699",
		"editor.wordHighlightStrongBackground": "#17e5e600",
		"editor.wordHighlightStrongBorder": "#17e5e666",
		"editorBracketHighlight.foreground1": "#79b8ff",
		"editorBracketHighlight.foreground2": "#ffab70",
		"editorBracketHighlight.foreground3": "#b392f0",
		"editorBracketHighlight.foreground4": "#79b8ff",
		"editorBracketHighlight.foreground5": "#ffab70",
		"editorBracketHighlight.foreground6": "#b392f0",
		"editorBracketMatch.background": "#17e5e650",
		"editorBracketMatch.border": "#17e5e600",
		"editorCursor.foreground": "#c8e1ff",
		"editorError.foreground": "#f97583",
		"editorGroup.border": "#1b1f23",
		"editorGroupHeader.tabsBackground": "#1f2428",
		"editorGroupHeader.tabsBorder": "#1b1f23",
		"editorGutter.addedBackground": "#28a745",
		"editorGutter.deletedBackground": "#ea4a5a",
		"editorGutter.modifiedBackground": "#2188ff",
		"editorIndentGuide.activeBackground": "#444d56",
		"editorIndentGuide.background": "#2f363d",
		"editorLineNumber.activeForeground": "#e1e4e8",
		"editorLineNumber.foreground": "#444d56",
		"editorOverviewRuler.border": "#1b1f23",
		"editorWarning.foreground": "#ffea7f",
		"editorWhitespace.foreground": "#444d56",
		"editorWidget.background": "#1f2428",
		"errorForeground": "#f97583",
		"focusBorder": "#005cc5",
		"foreground": "#d1d5da",
		"gitDecoration.addedResourceForeground": "#34d058",
		"gitDecoration.conflictingResourceForeground": "#ffab70",
		"gitDecoration.deletedResourceForeground": "#ea4a5a",
		"gitDecoration.ignoredResourceForeground": "#6a737d",
		"gitDecoration.modifiedResourceForeground": "#79b8ff",
		"gitDecoration.submoduleResourceForeground": "#6a737d",
		"gitDecoration.untrackedResourceForeground": "#34d058",
		"input.background": "#2f363d",
		"input.border": "#1b1f23",
		"input.foreground": "#e1e4e8",
		"input.placeholderForeground": "#959da5",
		"list.activeSelectionBackground": "#39414a",
		"list.activeSelectionForeground": "#e1e4e8",
		"list.focusBackground": "#044289",
		"list.hoverBackground": "#282e34",
		"list.hoverForeground": "#e1e4e8",
		"list.inactiveFocusBackground": "#1d2d3e",
		"list.inactiveSelectionBackground": "#282e34",
		"list.inactiveSelectionForeground": "#e1e4e8",
		"notificationCenterHeader.background": "#24292e",
		"notificationCenterHeader.foreground": "#959da5",
		"notifications.background": "#2f363d",
		"notifications.border": "#1b1f23",
		"notifications.foreground": "#e1e4e8",
		"notificationsErrorIcon.foreground": "#ea4a5a",
		"notificationsInfoIcon.foreground": "#79b8ff",
		"notificationsWarningIcon.foreground": "#ffab70",
		"panel.background": "#1f2428",
		"panel.border": "#1b1f23",
		"panelInput.border": "#2f363d",
		"panelTitle.activeBorder": "#f9826c",
		"panelTitle.activeForeground": "#e1e4e8",
		"panelTitle.inactiveForeground": "#959da5",
		"peekViewEditor.background": "#1f242888",
		"peekViewEditor.matchHighlightBackground": "#ffd33d33",
		"peekViewResult.background": "#1f2428",
		"peekViewResult.matchHighlightBackground": "#ffd33d33",
		"pickerGroup.border": "#444d56",
		"pickerGroup.foreground": "#e1e4e8",
		"progressBar.background": "#0366d6",
		"quickInput.background": "#24292e",
		"quickInput.foreground": "#e1e4e8",
		"scrollbar.shadow": "#00000088",
		"scrollbarSlider.activeBackground": "#6a737d88",
		"scrollbarSlider.background": "#6a737d33",
		"scrollbarSlider.hoverBackground": "#6a737d44",
		"settings.headerForeground": "#e1e4e8",
		"settings.modifiedItemIndicator": "#0366d6",
		"sideBar.background": "#1f2428",
		"sideBar.border": "#1b1f23",
		"sideBar.foreground": "#d1d5da",
		"sideBarSectionHeader.background": "#1f2428",
		"sideBarSectionHeader.border": "#1b1f23",
		"sideBarSectionHeader.foreground": "#e1e4e8",
		"sideBarTitle.foreground": "#e1e4e8",
		"statusBar.background": "#24292e",
		"statusBar.border": "#1b1f23",
		"statusBar.debuggingBackground": "#931c06",
		"statusBar.debuggingForeground": "#ffffff",
		"statusBar.foreground": "#d1d5da",
		"statusBar.noFolderBackground": "#24292e",
		"statusBarItem.prominentBackground": "#282e34",
		"statusBarItem.remoteBackground": "#24292e",
		"statusBarItem.remoteForeground": "#d1d5da",
		"tab.activeBackground": "#24292e",
		"tab.activeBorder": "#24292e",
		"tab.activeBorderTop": "#f9826c",
		"tab.activeForeground": "#e1e4e8",
		"tab.border": "#1b1f23",
		"tab.hoverBackground": "#24292e",
		"tab.inactiveBackground": "#1f2428",
		"tab.inactiveForeground": "#959da5",
		"tab.unfocusedActiveBorder": "#24292e",
		"tab.unfocusedActiveBorderTop": "#1b1f23",
		"tab.unfocusedHoverBackground": "#24292e",
		"terminal.ansiBlack": "#586069",
		"terminal.ansiBlue": "#2188ff",
		"terminal.ansiBrightBlack": "#959da5",
		"terminal.ansiBrightBlue": "#79b8ff",
		"terminal.ansiBrightCyan": "#56d4dd",
		"terminal.ansiBrightGreen": "#85e89d",
		"terminal.ansiBrightMagenta": "#b392f0",
		"terminal.ansiBrightRed": "#f97583",
		"terminal.ansiBrightWhite": "#fafbfc",
		"terminal.ansiBrightYellow": "#ffea7f",
		"terminal.ansiCyan": "#39c5cf",
		"terminal.ansiGreen": "#34d058",
		"terminal.ansiMagenta": "#b392f0",
		"terminal.ansiRed": "#ea4a5a",
		"terminal.ansiWhite": "#d1d5da",
		"terminal.ansiYellow": "#ffea7f",
		"terminal.foreground": "#d1d5da",
		"terminal.tab.activeBorder": "#f9826c",
		"terminalCursor.background": "#586069",
		"terminalCursor.foreground": "#79b8ff",
		"textBlockQuote.background": "#24292e",
		"textBlockQuote.border": "#444d56",
		"textCodeBlock.background": "#2f363d",
		"textLink.activeForeground": "#c8e1ff",
		"textLink.foreground": "#79b8ff",
		"textPreformat.foreground": "#d1d5da",
		"textSeparator.foreground": "#586069",
		"titleBar.activeBackground": "#24292e",
		"titleBar.activeForeground": "#e1e4e8",
		"titleBar.border": "#1b1f23",
		"titleBar.inactiveBackground": "#1f2428",
		"titleBar.inactiveForeground": "#959da5",
		"tree.indentGuidesStroke": "#2f363d",
		//"activityBar.dropBorder": "#e1e4e8",
		//"banner.background": "#39414a",
		//"banner.foreground": "#e1e4e8",
		//"banner.iconForeground": "#3794ff",
		//"breadcrumb.background": "#24292e",
		//"button.separator": "#dcffe466",
		//"charts.blue": "#3794ff",
		//"charts.foreground": "#d1d5da",
		//"charts.green": "#89d185",
		//"charts.lines": "#d1d5da80",
		//"charts.orange": "#d18616",
		//"charts.purple": "#b180d7",
		//"charts.red": "#f97583",
		//"charts.yellow": "#ffea7f",
		//"checkbox.foreground": "#e1e4e8",
		//"commandCenter.activeBackground": "#5a5d5e50",
		//"commandCenter.activeForeground": "#e1e4e8",
		//"commandCenter.border": "#1b1f23",
		//"commandCenter.foreground": "#e1e4e8",
		//"debugConsole.errorForeground": "#f97583",
		//"debugConsole.infoForeground": "#3794ff",
		//"debugConsole.sourceForeground": "#d1d5da",
		//"debugConsole.warningForeground": "#ffea7f",
		//"debugConsoleInputIcon.foreground": "#d1d5da",
		//"debugExceptionWidget.background": "#420b0d",
		//"debugExceptionWidget.border": "#a31515",
		//"debugIcon.breakpointCurrentStackframeForeground": "#ffcc00",
		//"debugIcon.breakpointDisabledForeground": "#848484",
		//"debugIcon.breakpointForeground": "#e51400",
		//"debugIcon.breakpointStackframeForeground": "#89d185",
		//"debugIcon.breakpointUnverifiedForeground": "#848484",
		//"debugIcon.continueForeground": "#75beff",
		//"debugIcon.disconnectForeground": "#f48771",
		//"debugIcon.pauseForeground": "#75beff",
		//"debugIcon.restartForeground": "#89d185",
		//"debugIcon.startForeground": "#89d185",
		//"debugIcon.stepBackForeground": "#75beff",
		//"debugIcon.stepIntoForeground": "#75beff",
		//"debugIcon.stepOutForeground": "#75beff",
		//"debugIcon.stepOverForeground": "#75beff",
		//"debugIcon.stopForeground": "#f48771",
		//"debugTokenExpression.boolean": "#4e94ce",
		//"debugTokenExpression.error": "#f48771",
		//"debugTokenExpression.name": "#c586c0",
		//"debugTokenExpression.number": "#b5cea8",
		//"debugTokenExpression.string": "#ce9178",
		//"debugTokenExpression.value": "#cccccc99",
		//"debugView.exceptionLabelBackground": "#6c2022",
		//"debugView.exceptionLabelForeground": "#d1d5da",
		//"debugView.stateLabelBackground": "#88888844",
		//"debugView.stateLabelForeground": "#d1d5da",
		//"debugView.valueChangedHighlight": "#569cd6",
		//"diffEditor.diagonalFill": "#cccccc33",
		//"diffEditor.insertedLineBackground": "#9bb95533",
		//"diffEditor.removedLineBackground": "#ff000033",
		//"disabledForeground": "#cccccc80",
		//"editor.findRangeHighlightBackground": "#3a3d4166",
		//"editor.hoverHighlightBackground": "#264f7840",
		//"editor.inlineValuesBackground": "#ffc80033",
		//"editor.inlineValuesForeground": "#ffffff80",
		//"editor.lineHighlightBorder": "#282828",
		//"editor.rangeHighlightBackground": "#ffffff0b",
		//"editor.snippetFinalTabstopHighlightBorder": "#525252",
		//"editor.snippetTabstopHighlightBackground": "#7c7c7c4d",
		//"editor.symbolHighlightBackground": "#ffd33d22",
		//"editorActiveLineNumber.foreground": "#c6c6c6",
		//"editorBracketHighlight.unexpectedBracket.foreground": "#ff1212cc",
		//"editorBracketPairGuide.activeBackground1": "#00000000",
		//"editorBracketPairGuide.activeBackground2": "#00000000",
		//"editorBracketPairGuide.activeBackground3": "#00000000",
		//"editorBracketPairGuide.activeBackground4": "#00000000",
		//"editorBracketPairGuide.activeBackground5": "#00000000",
		//"editorBracketPairGuide.activeBackground6": "#00000000",
		//"editorBracketPairGuide.background1": "#00000000",
		//"editorBracketPairGuide.background2": "#00000000",
		//"editorBracketPairGuide.background3": "#00000000",
		//"editorBracketPairGuide.background4": "#00000000",
		//"editorBracketPairGuide.background5": "#00000000",
		//"editorBracketPairGuide.background6": "#00000000",
		//"editorCodeLens.foreground": "#999999",
		//"editorCommentsWidget.rangeActiveBackground": "#3794ff1a",
		//"editorCommentsWidget.rangeActiveBorder": "#3794ff66",
		//"editorCommentsWidget.rangeBackground": "#3794ff1a",
		//"editorCommentsWidget.rangeBorder": "#3794ff66",
		//"editorCommentsWidget.resolvedBorder": "#cccccc80",
		//"editorCommentsWidget.unresolvedBorder": "#3794ff",
		//"editorGhostText.foreground": "#ffffff56",
		//"editorGroup.dropBackground": "#53595d80",
		//"editorGroup.dropIntoPromptBackground": "#1f2428",
		//"editorGroup.dropIntoPromptForeground": "#d1d5da",
		//"editorGroupHeader.noTabsBackground": "#24292e",
		//"editorGutter.background": "#24292e",
		//"editorGutter.commentRangeForeground": "#c5c5c5",
		//"editorGutter.foldingControlForeground": "#c5c5c5",
		//"editorHint.foreground": "#eeeeeeb3",
		//"editorHoverWidget.background": "#1f2428",
		//"editorHoverWidget.border": "#454545",
		//"editorHoverWidget.foreground": "#d1d5da",
		//"editorHoverWidget.highlightForeground": "#2aaaff",
		//"editorHoverWidget.statusBarBackground": "#252b30",
		//"editorInfo.foreground": "#3794ff",
		//"editorInlayHint.background": "#04428999",
		//"editorInlayHint.foreground": "#c8e1ffcc",
		//"editorInlayHint.parameterBackground": "#04428999",
		//"editorInlayHint.parameterForeground": "#c8e1ffcc",
		//"editorInlayHint.typeBackground": "#04428999",
		//"editorInlayHint.typeForeground": "#c8e1ffcc",
		//"editorLightBulb.foreground": "#ffcc00",
		//"editorLightBulbAutoFix.foreground": "#75beff",
		//"editorLink.activeForeground": "#4e94ce",
		//"editorMarkerNavigation.background": "#24292e",
		//"editorMarkerNavigationError.background": "#f97583",
		//"editorMarkerNavigationError.headerBackground": "#f975831a",
		//"editorMarkerNavigationInfo.background": "#3794ff",
		//"editorMarkerNavigationInfo.headerBackground": "#3794ff1a",
		//"editorMarkerNavigationWarning.background": "#ffea7f",
		//"editorMarkerNavigationWarning.headerBackground": "#ffea7f1a",
		//"editorOverviewRuler.addedForeground": "#28a74599",
		//"editorOverviewRuler.bracketMatchForeground": "#a0a0a0",
		//"editorOverviewRuler.commonContentForeground": "#60606066",
		//"editorOverviewRuler.currentContentForeground": "#40c8ae80",
		//"editorOverviewRuler.deletedForeground": "#ea4a5a99",
		//"editorOverviewRuler.errorForeground": "#ff1212b3",
		//"editorOverviewRuler.findMatchForeground": "#d186167e",
		//"editorOverviewRuler.incomingContentForeground": "#40a6ff80",
		//"editorOverviewRuler.infoForeground": "#3794ff",
		//"editorOverviewRuler.modifiedForeground": "#2188ff99",
		//"editorOverviewRuler.rangeHighlightForeground": "#007acc99",
		//"editorOverviewRuler.selectionHighlightForeground": "#a0a0a0cc",
		//"editorOverviewRuler.warningForeground": "#ffea7f",
		//"editorOverviewRuler.wordHighlightForeground": "#a0a0a0cc",
		//"editorOverviewRuler.wordHighlightStrongForeground": "#c0a0c0cc",
		//"editorPane.background": "#24292e",
		//"editorRuler.foreground": "#5a5a5a",
		//"editorStickyScroll.background": "#24292e",
		//"editorStickyScrollHover.background": "#2a2d2e",
		//"editorSuggestWidget.background": "#1f2428",
		//"editorSuggestWidget.border": "#454545",
		//"editorSuggestWidget.focusHighlightForeground": "#2aaaff",
		//"editorSuggestWidget.foreground": "#e1e4e8",
		//"editorSuggestWidget.highlightForeground": "#2aaaff",
		//"editorSuggestWidget.selectedBackground": "#39414a",
		//"editorSuggestWidget.selectedForeground": "#e1e4e8",
		//"editorSuggestWidgetStatus.foreground": "#e1e4e880",
		//"editorUnicodeHighlight.background": "#bd9b0326",
		//"editorUnicodeHighlight.border": "#bd9b03",
		//"editorUnnecessaryCode.opacity": "#000000aa",
		//"editorWidget.border": "#454545",
		//"editorWidget.foreground": "#d1d5da",
		//"extensionBadge.remoteBackground": "#0366d6",
		//"extensionBadge.remoteForeground": "#ffffff",
		//"extensionButton.prominentBackground": "#176f2c",
		//"extensionButton.prominentForeground": "#dcffe4",
		//"extensionButton.prominentHoverBackground": "#22863a",
		//"extensionIcon.preReleaseForeground": "#1d9271",
		//"extensionIcon.sponsorForeground": "#d758b3",
		//"extensionIcon.starForeground": "#ff8e00",
		//"extensionIcon.verifiedForeground": "#79b8ff",
		//"gitDecoration.renamedResourceForeground": "#73c991",
		//"gitDecoration.stageDeletedResourceForeground": "#c74e39",
		//"gitDecoration.stageModifiedResourceForeground": "#e2c08d",
		//"icon.foreground": "#c5c5c5",
		//"inputOption.activeBackground": "#005cc566",
		//"inputOption.activeBorder": "#007acc00",
		//"inputOption.activeForeground": "#ffffff",
		//"inputOption.hoverBackground": "#5a5d5e80",
		//"inputValidation.errorBackground": "#5a1d1d",
		//"inputValidation.errorBorder": "#be1100",
		//"inputValidation.infoBackground": "#063b49",
		//"inputValidation.infoBorder": "#007acc",
		//"inputValidation.warningBackground": "#352a05",
		//"inputValidation.warningBorder": "#b89500",
		//"interactive.activeCodeBorder": "#3794ff",
		//"interactive.inactiveCodeBorder": "#282e34",
		//"keybindingLabel.background": "#8080802b",
		//"keybindingLabel.border": "#33333399",
		//"keybindingLabel.bottomBorder": "#44444499",
		//"keybindingLabel.foreground": "#cccccc",
		//"keybindingTable.headerBackground": "#d1d5da0a",
		//"keybindingTable.rowsBackground": "#d1d5da0a",
		//"list.deemphasizedForeground": "#8c8c8c",
		//"list.dropBackground": "#062f4a",
		//"list.errorForeground": "#f88070",
		//"list.filterMatchBackground": "#ffd33d22",
		//"list.focusHighlightForeground": "#2aaaff",
		//"list.focusOutline": "#005cc5",
		//"list.highlightForeground": "#2aaaff",
		//"list.invalidItemForeground": "#b89500",
		//"list.warningForeground": "#cca700",
		//"listFilterWidget.background": "#1f2428",
		//"listFilterWidget.noMatchesOutline": "#be1100",
		//"listFilterWidget.outline": "#00000000",
		//"listFilterWidget.shadow": "#0000005c",
		//"menu.background": "#2f363d",
		//"menu.foreground": "#e1e4e8",
		//"menu.selectionBackground": "#39414a",
		//"menu.selectionForeground": "#e1e4e8",
		//"menu.separatorBackground": "#606060",
		//"menubar.selectionBackground": "#5a5d5e50",
		//"menubar.selectionForeground": "#e1e4e8",
		//"merge.commonContentBackground": "#60606029",
		//"merge.commonHeaderBackground": "#60606066",
		//"merge.currentContentBackground": "#40c8ae33",
		//"merge.currentHeaderBackground": "#40c8ae80",
		//"merge.incomingContentBackground": "#40a6ff33",
		//"merge.incomingHeaderBackground": "#40a6ff80",
		//"mergeEditor.change.background": "#9bb95533",
		//"mergeEditor.change.word.background": "#9bb9551e",
		//"mergeEditor.conflict.handled.minimapOverViewRuler": "#adaca8ee",
		//"mergeEditor.conflict.handledFocused.border": "#c1c1c1cc",
		//"mergeEditor.conflict.handledUnfocused.border": "#86868649",
		//"mergeEditor.conflict.unhandled.minimapOverViewRuler": "#fcba03",
		//"mergeEditor.conflict.unhandledFocused.border": "#ffa600",
		//"mergeEditor.conflict.unhandledUnfocused.border": "#ffa6007a",
		//"minimap.errorHighlight": "#ff1212b3",
		//"minimap.findMatchHighlight": "#d18616",
		//"minimap.foregroundOpacity": "#000000",
		//"minimap.selectionHighlight": "#264f78",
		//"minimap.selectionOccurrenceHighlight": "#676767",
		//"minimap.warningHighlight": "#ffea7f",
		//"minimapGutter.addedBackground": "#28a745",
		//"minimapGutter.deletedBackground": "#ea4a5a",
		//"minimapGutter.modifiedBackground": "#2188ff",
		//"minimapSlider.activeBackground": "#6a737d44",
		//"minimapSlider.background": "#6a737d1a",
		//"minimapSlider.hoverBackground": "#6a737d22",
		//"notebook.cellBorderColor": "#282e34",
		//"notebook.cellEditorBackground": "#1f2428",
		//"notebook.cellInsertionIndicator": "#005cc5",
		//"notebook.cellStatusBarItemHoverBackground": "#ffffff26",
		//"notebook.cellToolbarSeparator": "#80808059",
		//"notebook.editorBackground": "#24292e",
		//"notebook.focusedCellBorder": "#005cc5",
		//"notebook.focusedEditorBorder": "#005cc5",
		//"notebook.inactiveFocusedCellBorder": "#282e34",
		//"notebook.selectedCellBackground": "#282e34",
		//"notebook.selectedCellBorder": "#282e34",
		//"notebook.symbolHighlightBackground": "#ffffff0b",
		//"notebookScrollbarSlider.activeBackground": "#6a737d88",
		//"notebookScrollbarSlider.background": "#6a737d33",
		//"notebookScrollbarSlider.hoverBackground": "#6a737d44",
		//"notebookStatusErrorIcon.foreground": "#f97583",
		//"notebookStatusRunningIcon.foreground": "#d1d5da",
		//"notebookStatusSuccessIcon.foreground": "#89d185",
		//"notificationLink.foreground": "#79b8ff",
		//"panel.dropBorder": "#e1e4e8",
		//"panelSection.border": "#1b1f23",
		//"panelSection.dropBackground": "#53595d80",
		//"panelSectionHeader.background": "#80808033",
		//"peekView.border": "#3794ff",
		//"peekViewEditorGutter.background": "#1f242888",
		//"peekViewResult.fileForeground": "#ffffff",
		//"peekViewResult.lineForeground": "#bbbbbb",
		//"peekViewResult.selectionBackground": "#3399ff33",
		//"peekViewResult.selectionForeground": "#ffffff",
		//"peekViewTitle.background": "#3794ff1a",
		//"peekViewTitleDescription.foreground": "#ccccccb3",
		//"peekViewTitleLabel.foreground": "#ffffff",
		//"ports.iconRunningProcessForeground": "#24292e",
		//"problemsErrorIcon.foreground": "#f97583",
		//"problemsInfoIcon.foreground": "#3794ff",
		//"problemsWarningIcon.foreground": "#ffea7f",
		//"quickInputList.focusBackground": "#39414a",
		//"quickInputList.focusForeground": "#e1e4e8",
		//"quickInputTitle.background": "#ffffff1b",
		//"sash.hoverBorder": "#005cc5",
		//"scm.providerBorder": "#454545",
		//"searchEditor.findMatchBackground": "#ffd33d16",
		//"searchEditor.textInputBorder": "#1b1f23",
		//"settings.checkboxBackground": "#444d56",
		//"settings.checkboxBorder": "#1b1f23",
		//"settings.checkboxForeground": "#e1e4e8",
		//"settings.dropdownBackground": "#2f363d",
		//"settings.dropdownBorder": "#1b1f23",
		//"settings.dropdownForeground": "#e1e4e8",
		//"settings.dropdownListBorder": "#454545",
		//"settings.focusedRowBackground": "#282e3499",
		//"settings.focusedRowBorder": "#ffffff1f",
		//"settings.headerBorder": "#1b1f23",
		//"settings.numberInputBackground": "#2f363d",
		//"settings.numberInputBorder": "#1b1f23",
		//"settings.numberInputForeground": "#e1e4e8",
		//"settings.rowHoverBackground": "#282e344d",
		//"settings.sashBorder": "#1b1f23",
		//"settings.textInputBackground": "#2f363d",
		//"settings.textInputBorder": "#1b1f23",
		//"settings.textInputForeground": "#e1e4e8",
		//"sideBar.dropBackground": "#53595d80",
		//"sideBySideEditor.horizontalBorder": "#1b1f23",
		//"sideBySideEditor.verticalBorder": "#1b1f23",
		//"statusBar.debuggingBorder": "#1b1f23",
		//"statusBar.focusBorder": "#d1d5da",
		//"statusBar.noFolderBorder": "#1b1f23",
		//"statusBar.noFolderForeground": "#d1d5da",
		//"statusBarItem.activeBackground": "#ffffff2e",
		//"statusBarItem.compactHoverBackground": "#ffffff33",
		//"statusBarItem.errorBackground": "#d3091d",
		//"statusBarItem.errorForeground": "#ffffff",
		//"statusBarItem.focusBorder": "#d1d5da",
		//"statusBarItem.hoverBackground": "#ffffff1f",
		//"statusBarItem.prominentForeground": "#d1d5da",
		//"statusBarItem.prominentHoverBackground": "#0000004d",
		//"statusBarItem.warningBackground": "#e5bf00",
		//"statusBarItem.warningForeground": "#ffffff",
		//"symbolIcon.arrayForeground": "#d1d5da",
		//"symbolIcon.booleanForeground": "#d1d5da",
		//"symbolIcon.classForeground": "#ee9d28",
		//"symbolIcon.colorForeground": "#d1d5da",
		//"symbolIcon.constantForeground": "#d1d5da",
		//"symbolIcon.constructorForeground": "#b180d7",
		//"symbolIcon.enumeratorForeground": "#ee9d28",
		//"symbolIcon.enumeratorMemberForeground": "#75beff",
		//"symbolIcon.eventForeground": "#ee9d28",
		//"symbolIcon.fieldForeground": "#75beff",
		//"symbolIcon.fileForeground": "#d1d5da",
		//"symbolIcon.folderForeground": "#d1d5da",
		//"symbolIcon.functionForeground": "#b180d7",
		//"symbolIcon.interfaceForeground": "#75beff",
		//"symbolIcon.keyForeground": "#d1d5da",
		//"symbolIcon.keywordForeground": "#d1d5da",
		//"symbolIcon.methodForeground": "#b180d7",
		//"symbolIcon.moduleForeground": "#d1d5da",
		//"symbolIcon.namespaceForeground": "#d1d5da",
		//"symbolIcon.nullForeground": "#d1d5da",
		//"symbolIcon.numberForeground": "#d1d5da",
		//"symbolIcon.objectForeground": "#d1d5da",
		//"symbolIcon.operatorForeground": "#d1d5da",
		//"symbolIcon.packageForeground": "#d1d5da",
		//"symbolIcon.propertyForeground": "#d1d5da",
		//"symbolIcon.referenceForeground": "#d1d5da",
		//"symbolIcon.snippetForeground": "#d1d5da",
		//"symbolIcon.stringForeground": "#d1d5da",
		//"symbolIcon.structForeground": "#d1d5da",
		//"symbolIcon.textForeground": "#d1d5da",
		//"symbolIcon.typeParameterForeground": "#d1d5da",
		//"symbolIcon.unitForeground": "#d1d5da",
		//"symbolIcon.variableForeground": "#75beff",
		//"tab.activeModifiedBorder": "#3399cc",
		//"tab.inactiveModifiedBorder": "#3399cc80",
		//"tab.lastPinnedBorder": "#2f363d",
		//"tab.unfocusedActiveBackground": "#24292e",
		//"tab.unfocusedActiveForeground": "#e1e4e880",
		//"tab.unfocusedActiveModifiedBorder": "#3399cc80",
		//"tab.unfocusedInactiveBackground": "#1f2428",
		//"tab.unfocusedInactiveForeground": "#959da580",
		//"tab.unfocusedInactiveModifiedBorder": "#3399cc40",
		//"terminal.border": "#1b1f23",
		//"terminal.dropBackground": "#53595d80",
		//"terminal.findMatchBackground": "#ffd33d44",
		//"terminal.findMatchHighlightBackground": "#ffd33d22",
		//"terminal.selectionBackground": "#3392ff44",
		//"terminalCommandDecoration.defaultBackground": "#ffffff40",
		//"terminalCommandDecoration.errorBackground": "#f14c4c",
		//"terminalCommandDecoration.successBackground": "#1b81a8",
		//"terminalOverviewRuler.cursorForeground": "#a0a0a0cc",
		//"terminalOverviewRuler.findMatchForeground": "#d186167e",
		//"testing.iconErrored": "#f14c4c",
		//"testing.iconFailed": "#f14c4c",
		//"testing.iconPassed": "#73c991",
		//"testing.iconQueued": "#cca700",
		//"testing.iconSkipped": "#848484",
		//"testing.iconUnset": "#848484",
		//"testing.message.error.decorationForeground": "#f97583",
		//"testing.message.error.lineBackground": "#ff000033",
		//"testing.message.info.decorationForeground": "#e1e4e880",
		//"testing.peekBorder": "#f97583",
		//"testing.peekHeaderBackground": "#f975831a",
		//"testing.runAction": "#73c991",
		//"toolbar.activeBackground": "#63666750",
		//"toolbar.hoverBackground": "#5a5d5e50",
		//"tree.tableColumnsBorder": "#cccccc20",
		//"tree.tableOddRowsBackground": "#d1d5da0a",
		//"welcomePage.progress.background": "#2f363d",
		//"welcomePage.progress.foreground": "#79b8ff",
		//"welcomePage.tileBackground": "#1f2428",
		//"welcomePage.tileHoverBackground": "#252b30",
		//"welcomePage.tileShadow": "#0000005c",
		//"widget.shadow": "#0000005c",
		//"activityBar.activeBackground": null,
		//"activityBar.activeFocusBorder": null,
		//"button.border": null,
		//"commandCenter.background": null,
		//"contrastActiveBorder": null,
		//"contrastBorder": null,
		//"debugToolBar.border": null,
		//"diffEditor.border": null,
		//"diffEditor.insertedTextBorder": null,
		//"diffEditor.removedTextBorder": null,
		//"diffEditorGutter.insertedLineBackground": null,
		//"diffEditorGutter.removedLineBackground": null,
		//"diffEditorOverview.insertedForeground": null,
		//"diffEditorOverview.removedForeground": null,
		//"editor.findMatchBorder": null,
		//"editor.findMatchHighlightBorder": null,
		//"editor.findRangeHighlightBorder": null,
		//"editor.rangeHighlightBorder": null,
		//"editor.selectionForeground": null,
		//"editor.snippetFinalTabstopHighlightBackground": null,
		//"editor.snippetTabstopHighlightBorder": null,
		//"editor.symbolHighlightBorder": null,
		//"editorCursor.background": null,
		//"editorError.background": null,
		//"editorError.border": null,
		//"editorGhostText.background": null,
		//"editorGhostText.border": null,
		//"editorGroup.dropIntoPromptBorder": null,
		//"editorGroup.emptyBackground": null,
		//"editorGroup.focusedEmptyBorder": null,
		//"editorGroupHeader.border": null,
		//"editorHint.border": null,
		//"editorInfo.background": null,
		//"editorInfo.border": null,
		//"editorOverviewRuler.background": null,
		//"editorSuggestWidget.selectedIconForeground": null,
		//"editorUnnecessaryCode.border": null,
		//"editorWarning.background": null,
		//"editorWarning.border": null,
		//"editorWidget.resizeBorder": null,
		//"inputValidation.errorForeground": null,
		//"inputValidation.infoForeground": null,
		//"inputValidation.warningForeground": null,
		//"list.activeSelectionIconForeground": null,
		//"list.filterMatchBorder": null,
		//"list.focusAndSelectionOutline": null,
		//"list.focusForeground": null,
		//"list.inactiveFocusOutline": null,
		//"list.inactiveSelectionIconForeground": null,
		//"menu.border": null,
		//"menu.selectionBorder": null,
		//"menubar.selectionBorder": null,
		//"merge.border": null,
		//"minimap.background": null,
		//"notebook.cellHoverBackground": null,
		//"notebook.focusedCellBackground": null,
		//"notebook.inactiveSelectedCellBorder": null,
		//"notebook.outputContainerBackgroundColor": null,
		//"notebook.outputContainerBorderColor": null,
		//"notificationCenter.border": null,
		//"notificationToast.border": null,
		//"panelSectionHeader.border": null,
		//"panelSectionHeader.foreground": null,
		//"peekViewEditor.matchHighlightBorder": null,
		//"quickInput.list.focusBackground": null,
		//"quickInputList.focusIconForeground": null,
		//"searchEditor.findMatchBorder": null,
		//"selection.background": null,
		//"statusBarItem.settingsProfilesBackground": null,
		//"statusBarItem.settingsProfilesForeground": null,
		//"tab.hoverBorder": null,
		//"tab.hoverForeground": null,
		//"tab.unfocusedHoverBorder": null,
		//"tab.unfocusedHoverForeground": null,
		//"terminal.background": null,
		//"terminal.findMatchBorder": null,
		//"terminal.findMatchHighlightBorder": null,
		//"terminal.selectionForeground": null,
		//"testing.message.info.lineBackground": null,
		//"toolbar.hoverOutline": null,
		//"walkThrough.embeddedEditorBackground": null,
		//"welcomePage.background": null,
		//"window.activeBorder": null,
		//"window.inactiveBorder": null
	},
	"tokenColors": [
		{
			"scope": [
				"comment",
				"punctuation.definition.comment",
				"string.comment"
			],
			"settings": {
				"foreground": "#6A737D"
			}
		},
		{
			"scope": [
				"constant",
				"entity.name.constant",
				"variable.other.constant",
				"variable.other.enummember",
				"variable.language"
			],
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": [
				"entity",
				"entity.name"
			],
			"settings": {
				"foreground": "#B392F0"
			}
		},
		{
			"scope": "variable.parameter.function",
			"settings": {
				"foreground": "#E1E4E8"
			}
		},
		{
			"scope": "entity.name.tag",
			"settings": {
				"foreground": "#85E89D"
			}
		},
		{
			"scope": "keyword",
			"settings": {
				"foreground": "#F97583"
			}
		},
		{
			"scope": [
				"storage",
				"storage.type"
			],
			"settings": {
				"foreground": "#F97583"
			}
		},
		{
			"scope": [
				"storage.modifier.package",
				"storage.modifier.import",
				"storage.type.java"
			],
			"settings": {
				"foreground": "#E1E4E8"
			}
		},
		{
			"scope": [
				"string",
				"punctuation.definition.string",
				"string punctuation.section.embedded source"
			],
			"settings": {
				"foreground": "#9ECBFF"
			}
		},
		{
			"scope": "support",
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": "meta.property-name",
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": "variable",
			"settings": {
				"foreground": "#FFAB70"
			}
		},
		{
			"scope": "variable.other",
			"settings": {
				"foreground": "#E1E4E8"
			}
		},
		{
			"scope": "invalid.broken",
			"settings": {
				"foreground": "#FDAEB7",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "invalid.deprecated",
			"settings": {
				"foreground": "#FDAEB7",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "invalid.illegal",
			"settings": {
				"foreground": "#FDAEB7",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "invalid.unimplemented",
			"settings": {
				"foreground": "#FDAEB7",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "carriage-return",
			"settings": {
				"foreground": "#24292E",
				"background": "#F97583",
				"fontStyle": "italic underline"
			}
		},
		{
			"scope": "message.error",
			"settings": {
				"foreground": "#FDAEB7"
			}
		},
		{
			"scope": "string variable",
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": [
				"source.regexp",
				"string.regexp"
			],
			"settings": {
				"foreground": "#DBEDFF"
			}
		},
		{
			"scope": [
				"string.regexp.character-class",
				"string.regexp constant.character.escape",
				"string.regexp source.ruby.embedded",
				"string.regexp string.regexp.arbitrary-repitition"
			],
			"settings": {
				"foreground": "#DBEDFF"
			}
		},
		{
			"scope": "string.regexp constant.character.escape",
			"settings": {
				"foreground": "#85E89D",
				"fontStyle": "bold"
			}
		},
		{
			"scope": "support.constant",
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": "support.variable",
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": "meta.module-reference",
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": "punctuation.definition.list.begin.markdown",
			"settings": {
				"foreground": "#FFAB70"
			}
		},
		{
			"scope": [
				"markup.heading",
				"markup.heading entity.name"
			],
			"settings": {
				"foreground": "#79B8FF",
				"fontStyle": "bold"
			}
		},
		{
			"scope": "markup.quote",
			"settings": {
				"foreground": "#85E89D"
			}
		},
		{
			"scope": "markup.italic",
			"settings": {
				"foreground": "#E1E4E8",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "markup.bold",
			"settings": {
				"foreground": "#E1E4E8",
				"fontStyle": "bold"
			}
		},
		{
			"scope": [
				"markup.underline"
			],
			"settings": {
				"fontStyle": "underline"
			}
		},
		{
			"scope": [
				"markup.strikethrough"
			],
			"settings": {
				"fontStyle": "strikethrough"
			}
		},
		{
			"scope": "markup.inline.raw",
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": [
				"markup.deleted",
				"meta.diff.header.from-file",
				"punctuation.definition.deleted"
			],
			"settings": {
				"foreground": "#FDAEB7",
				"background": "#86181D"
			}
		},
		{
			"scope": [
				"markup.inserted",
				"meta.diff.header.to-file",
				"punctuation.definition.inserted"
			],
			"settings": {
				"foreground": "#85E89D",
				"background": "#144620"
			}
		},
		{
			"scope": [
				"markup.changed",
				"punctuation.definition.changed"
			],
			"settings": {
				"foreground": "#FFAB70",
				"background": "#C24E00"
			}
		},
		{
			"scope": [
				"markup.ignored",
				"markup.untracked"
			],
			"settings": {
				"foreground": "#2F363D",
				"background": "#79B8FF"
			}
		},
		{
			"scope": "meta.diff.range",
			"settings": {
				"foreground": "#B392F0",
				"fontStyle": "bold"
			}
		},
		{
			"scope": "meta.diff.header",
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": "meta.separator",
			"settings": {
				"foreground": "#79B8FF",
				"fontStyle": "bold"
			}
		},
		{
			"scope": "meta.output",
			"settings": {
				"foreground": "#79B8FF"
			}
		},
		{
			"scope": [
				"brackethighlighter.tag",
				"brackethighlighter.curly",
				"brackethighlighter.round",
				"brackethighlighter.square",
				"brackethighlighter.angle",
				"brackethighlighter.quote"
			],
			"settings": {
				"foreground": "#D1D5DA"
			}
		},
		{
			"scope": "brackethighlighter.unmatched",
			"settings": {
				"foreground": "#FDAEB7"
			}
		},
		{
			"scope": [
				"constant.other.reference.link",
				"string.other.link"
			],
			"settings": {
				"foreground": "#DBEDFF",
				"fontStyle": "underline"
			}
		},
		{
			"scope": "token.info-token",
			"settings": {
				"foreground": "#6796E6"
			}
		},
		{
			"scope": "token.warn-token",
			"settings": {
				"foreground": "#CD9731"
			}
		},
		{
			"scope": "token.error-token",
			"settings": {
				"foreground": "#F44747"
			}
		},
		{
			"scope": "token.debug-token",
			"settings": {
				"foreground": "#B267E6"
			}
		}
	]
}