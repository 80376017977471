module.exports = {
	"$schema": "vscode://schemas/color-theme",
	"type": "light",
	"colors": {
		"activityBar.activeBorder": "#f9826c",
		"activityBar.background": "#ffffff",
		"activityBar.border": "#e1e4e8",
		"activityBar.foreground": "#2f363d",
		"activityBar.inactiveForeground": "#959da5",
		"activityBarBadge.background": "#2188ff",
		"activityBarBadge.foreground": "#ffffff",
		"badge.background": "#dbedff",
		"badge.foreground": "#005cc5",
		"breadcrumb.activeSelectionForeground": "#586069",
		"breadcrumb.focusForeground": "#2f363d",
		"breadcrumb.foreground": "#6a737d",
		"breadcrumbPicker.background": "#fafbfc",
		"button.background": "#159739",
		"button.foreground": "#ffffff",
		"button.hoverBackground": "#138934",
		"button.secondaryBackground": "#e1e4e8",
		"button.secondaryForeground": "#1b1f23",
		"button.secondaryHoverBackground": "#d1d5da",
		"checkbox.background": "#fafbfc",
		"checkbox.border": "#d1d5da",
		"debugToolBar.background": "#ffffff",
		"descriptionForeground": "#6a737d",
		"diffEditor.insertedTextBackground": "#34d05822",
		"diffEditor.removedTextBackground": "#d73a4922",
		"dropdown.background": "#fafbfc",
		"dropdown.border": "#e1e4e8",
		"dropdown.foreground": "#2f363d",
		"dropdown.listBackground": "#ffffff",
		"editor.background": "#ffffff",
		"editor.findMatchBackground": "#ffdf5d",
		"editor.findMatchHighlightBackground": "#ffdf5d66",
		"editor.focusedStackFrameHighlightBackground": "#28a74525",
		"editor.foldBackground": "#d1d5da11",
		"editor.foreground": "#24292e",
		"editor.inactiveSelectionBackground": "#0366d611",
		"editor.lineHighlightBackground": "#f6f8fa",
		"editor.linkedEditingBackground": "#0366d611",
		"editor.selectionBackground": "#0366d625",
		"editor.selectionHighlightBackground": "#34d05840",
		"editor.selectionHighlightBorder": "#34d05800",
		"editor.stackFrameHighlightBackground": "#ffd33d33",
		"editor.wordHighlightBackground": "#34d05800",
		"editor.wordHighlightBorder": "#24943e99",
		"editor.wordHighlightStrongBackground": "#34d05800",
		"editor.wordHighlightStrongBorder": "#24943e50",
		"editorBracketHighlight.foreground1": "#005cc5",
		"editorBracketHighlight.foreground2": "#e36209",
		"editorBracketHighlight.foreground3": "#5a32a3",
		"editorBracketHighlight.foreground4": "#005cc5",
		"editorBracketHighlight.foreground5": "#e36209",
		"editorBracketHighlight.foreground6": "#5a32a3",
		"editorBracketMatch.background": "#34d05840",
		"editorBracketMatch.border": "#34d05800",
		"editorCursor.foreground": "#044289",
		"editorError.foreground": "#cb2431",
		"editorGroup.border": "#e1e4e8",
		"editorGroupHeader.tabsBackground": "#f6f8fa",
		"editorGroupHeader.tabsBorder": "#e1e4e8",
		"editorGutter.addedBackground": "#28a745",
		"editorGutter.deletedBackground": "#d73a49",
		"editorGutter.modifiedBackground": "#2188ff",
		"editorIndentGuide.activeBackground": "#d7dbe0",
		"editorIndentGuide.background": "#eff2f6",
		"editorLineNumber.activeForeground": "#24292e",
		"editorLineNumber.foreground": "#1b1f234d",
		"editorOverviewRuler.border": "#ffffff",
		"editorWarning.foreground": "#f9c513",
		"editorWhitespace.foreground": "#d1d5da",
		"editorWidget.background": "#f6f8fa",
		"errorForeground": "#cb2431",
		"focusBorder": "#2188ff",
		"foreground": "#444d56",
		"gitDecoration.addedResourceForeground": "#28a745",
		"gitDecoration.conflictingResourceForeground": "#e36209",
		"gitDecoration.deletedResourceForeground": "#d73a49",
		"gitDecoration.ignoredResourceForeground": "#959da5",
		"gitDecoration.modifiedResourceForeground": "#005cc5",
		"gitDecoration.submoduleResourceForeground": "#959da5",
		"gitDecoration.untrackedResourceForeground": "#28a745",
		"input.background": "#fafbfc",
		"input.border": "#e1e4e8",
		"input.foreground": "#2f363d",
		"input.placeholderForeground": "#959da5",
		"list.activeSelectionBackground": "#e2e5e9",
		"list.activeSelectionForeground": "#2f363d",
		"list.focusBackground": "#cce5ff",
		"list.hoverBackground": "#ebf0f4",
		"list.hoverForeground": "#2f363d",
		"list.inactiveFocusBackground": "#dbedff",
		"list.inactiveSelectionBackground": "#e8eaed",
		"list.inactiveSelectionForeground": "#2f363d",
		"notificationCenterHeader.background": "#e1e4e8",
		"notificationCenterHeader.foreground": "#6a737d",
		"notifications.background": "#fafbfc",
		"notifications.border": "#e1e4e8",
		"notifications.foreground": "#2f363d",
		"notificationsErrorIcon.foreground": "#d73a49",
		"notificationsInfoIcon.foreground": "#005cc5",
		"notificationsWarningIcon.foreground": "#e36209",
		"panel.background": "#f6f8fa",
		"panel.border": "#e1e4e8",
		"panelInput.border": "#e1e4e8",
		"panelTitle.activeBorder": "#f9826c",
		"panelTitle.activeForeground": "#2f363d",
		"panelTitle.inactiveForeground": "#6a737d",
		"pickerGroup.border": "#e1e4e8",
		"pickerGroup.foreground": "#2f363d",
		"progressBar.background": "#2188ff",
		"quickInput.background": "#fafbfc",
		"quickInput.foreground": "#2f363d",
		"scrollbar.shadow": "#6a737d33",
		"scrollbarSlider.activeBackground": "#959da588",
		"scrollbarSlider.background": "#959da533",
		"scrollbarSlider.hoverBackground": "#959da544",
		"settings.headerForeground": "#2f363d",
		"settings.modifiedItemIndicator": "#2188ff",
		"sideBar.background": "#f6f8fa",
		"sideBar.border": "#e1e4e8",
		"sideBar.foreground": "#586069",
		"sideBarSectionHeader.background": "#f6f8fa",
		"sideBarSectionHeader.border": "#e1e4e8",
		"sideBarSectionHeader.foreground": "#2f363d",
		"sideBarTitle.foreground": "#2f363d",
		"statusBar.background": "#ffffff",
		"statusBar.border": "#e1e4e8",
		"statusBar.debuggingBackground": "#f9826c",
		"statusBar.debuggingForeground": "#ffffff",
		"statusBar.foreground": "#586069",
		"statusBar.noFolderBackground": "#ffffff",
		"statusBarItem.prominentBackground": "#e8eaed",
		"statusBarItem.remoteBackground": "#ffffff",
		"statusBarItem.remoteForeground": "#586069",
		"tab.activeBackground": "#ffffff",
		"tab.activeBorder": "#ffffff",
		"tab.activeBorderTop": "#f9826c",
		"tab.activeForeground": "#2f363d",
		"tab.border": "#e1e4e8",
		"tab.hoverBackground": "#ffffff",
		"tab.inactiveBackground": "#f6f8fa",
		"tab.inactiveForeground": "#6a737d",
		"tab.unfocusedActiveBorder": "#ffffff",
		"tab.unfocusedActiveBorderTop": "#e1e4e8",
		"tab.unfocusedHoverBackground": "#ffffff",
		"terminal.ansiBlack": "#24292e",
		"terminal.ansiBlue": "#0366d6",
		"terminal.ansiBrightBlack": "#959da5",
		"terminal.ansiBrightBlue": "#005cc5",
		"terminal.ansiBrightCyan": "#3192aa",
		"terminal.ansiBrightGreen": "#22863a",
		"terminal.ansiBrightMagenta": "#5a32a3",
		"terminal.ansiBrightRed": "#cb2431",
		"terminal.ansiBrightWhite": "#d1d5da",
		"terminal.ansiBrightYellow": "#b08800",
		"terminal.ansiCyan": "#1b7c83",
		"terminal.ansiGreen": "#28a745",
		"terminal.ansiMagenta": "#5a32a3",
		"terminal.ansiRed": "#d73a49",
		"terminal.ansiWhite": "#6a737d",
		"terminal.ansiYellow": "#dbab09",
		"terminal.foreground": "#586069",
		"terminal.tab.activeBorder": "#f9826c",
		"terminalCursor.background": "#d1d5da",
		"terminalCursor.foreground": "#005cc5",
		"textBlockQuote.background": "#fafbfc",
		"textBlockQuote.border": "#e1e4e8",
		"textCodeBlock.background": "#f6f8fa",
		"textLink.activeForeground": "#005cc5",
		"textLink.foreground": "#0366d6",
		"textPreformat.foreground": "#586069",
		"textSeparator.foreground": "#d1d5da",
		"titleBar.activeBackground": "#ffffff",
		"titleBar.activeForeground": "#2f363d",
		"titleBar.border": "#e1e4e8",
		"titleBar.inactiveBackground": "#f6f8fa",
		"titleBar.inactiveForeground": "#6a737d",
		"tree.indentGuidesStroke": "#e1e4e8",
		//"activityBar.dropBorder": "#2f363d",
		//"banner.background": "#949fae",
		//"banner.foreground": "#2f363d",
		//"banner.iconForeground": "#1a85ff",
		//"breadcrumb.background": "#ffffff",
		//"button.separator": "#ffffff66",
		//"charts.blue": "#1a85ff",
		//"charts.foreground": "#444d56",
		//"charts.green": "#388a34",
		//"charts.lines": "#444d5680",
		//"charts.orange": "#d18616",
		//"charts.purple": "#652d90",
		//"charts.red": "#cb2431",
		//"charts.yellow": "#f9c513",
		//"checkbox.foreground": "#2f363d",
		//"commandCenter.activeBackground": "#b8b8b850",
		//"commandCenter.activeForeground": "#2f363d",
		//"commandCenter.border": "#e1e4e8",
		//"commandCenter.foreground": "#2f363d",
		//"debugConsole.errorForeground": "#cb2431",
		//"debugConsole.infoForeground": "#1a85ff",
		//"debugConsole.sourceForeground": "#444d56",
		//"debugConsole.warningForeground": "#f9c513",
		//"debugConsoleInputIcon.foreground": "#444d56",
		//"debugExceptionWidget.background": "#f1dfde",
		//"debugExceptionWidget.border": "#a31515",
		//"debugIcon.breakpointCurrentStackframeForeground": "#be8700",
		//"debugIcon.breakpointDisabledForeground": "#848484",
		//"debugIcon.breakpointForeground": "#e51400",
		//"debugIcon.breakpointStackframeForeground": "#89d185",
		//"debugIcon.breakpointUnverifiedForeground": "#848484",
		//"debugIcon.continueForeground": "#007acc",
		//"debugIcon.disconnectForeground": "#a1260d",
		//"debugIcon.pauseForeground": "#007acc",
		//"debugIcon.restartForeground": "#388a34",
		//"debugIcon.startForeground": "#388a34",
		//"debugIcon.stepBackForeground": "#007acc",
		//"debugIcon.stepIntoForeground": "#007acc",
		//"debugIcon.stepOutForeground": "#007acc",
		//"debugIcon.stepOverForeground": "#007acc",
		//"debugIcon.stopForeground": "#a1260d",
		//"debugTokenExpression.boolean": "#0000ff",
		//"debugTokenExpression.error": "#e51400",
		//"debugTokenExpression.name": "#9b46b0",
		//"debugTokenExpression.number": "#098658",
		//"debugTokenExpression.string": "#a31515",
		//"debugTokenExpression.value": "#6c6c6ccc",
		//"debugView.exceptionLabelBackground": "#a31515",
		//"debugView.exceptionLabelForeground": "#ffffff",
		//"debugView.stateLabelBackground": "#88888844",
		//"debugView.stateLabelForeground": "#444d56",
		//"debugView.valueChangedHighlight": "#569cd6",
		//"diffEditor.diagonalFill": "#22222233",
		//"diffEditor.insertedLineBackground": "#9bb95533",
		//"diffEditor.removedLineBackground": "#ff000033",
		//"disabledForeground": "#61616180",
		//"editor.findRangeHighlightBackground": "#b4b4b44d",
		//"editor.hoverHighlightBackground": "#add6ff26",
		//"editor.inlineValuesBackground": "#ffc80033",
		//"editor.inlineValuesForeground": "#00000080",
		//"editor.lineHighlightBorder": "#eeeeee",
		//"editor.rangeHighlightBackground": "#fdff0033",
		//"editor.snippetFinalTabstopHighlightBorder": "#0a326480",
		//"editor.snippetTabstopHighlightBackground": "#0a326433",
		//"editor.symbolHighlightBackground": "#ffdf5d66",
		//"editorActiveLineNumber.foreground": "#0b216f",
		//"editorBracketHighlight.unexpectedBracket.foreground": "#ff1212cc",
		//"editorBracketPairGuide.activeBackground1": "#00000000",
		//"editorBracketPairGuide.activeBackground2": "#00000000",
		//"editorBracketPairGuide.activeBackground3": "#00000000",
		//"editorBracketPairGuide.activeBackground4": "#00000000",
		//"editorBracketPairGuide.activeBackground5": "#00000000",
		//"editorBracketPairGuide.activeBackground6": "#00000000",
		//"editorBracketPairGuide.background1": "#00000000",
		//"editorBracketPairGuide.background2": "#00000000",
		//"editorBracketPairGuide.background3": "#00000000",
		//"editorBracketPairGuide.background4": "#00000000",
		//"editorBracketPairGuide.background5": "#00000000",
		//"editorBracketPairGuide.background6": "#00000000",
		//"editorCodeLens.foreground": "#919191",
		//"editorCommentsWidget.rangeActiveBackground": "#1a85ff1a",
		//"editorCommentsWidget.rangeActiveBorder": "#1a85ff66",
		//"editorCommentsWidget.rangeBackground": "#1a85ff1a",
		//"editorCommentsWidget.rangeBorder": "#1a85ff66",
		//"editorCommentsWidget.resolvedBorder": "#61616180",
		//"editorCommentsWidget.unresolvedBorder": "#1a85ff",
		//"editorGhostText.foreground": "#00000077",
		//"editorGroup.dropBackground": "#2677cb2e",
		//"editorGroup.dropIntoPromptBackground": "#f6f8fa",
		//"editorGroup.dropIntoPromptForeground": "#444d56",
		//"editorGroupHeader.noTabsBackground": "#ffffff",
		//"editorGutter.background": "#ffffff",
		//"editorGutter.commentRangeForeground": "#c5c5c5",
		//"editorGutter.foldingControlForeground": "#424242",
		//"editorHint.foreground": "#6c6c6c",
		//"editorHoverWidget.background": "#f6f8fa",
		//"editorHoverWidget.border": "#c8c8c8",
		//"editorHoverWidget.foreground": "#444d56",
		//"editorHoverWidget.highlightForeground": "#0066bf",
		//"editorHoverWidget.statusBarBackground": "#e6ecf1",
		//"editorInfo.foreground": "#1a85ff",
		//"editorInlayHint.background": "#dbedff4d",
		//"editorInlayHint.foreground": "#005cc5cc",
		//"editorInlayHint.parameterBackground": "#dbedff4d",
		//"editorInlayHint.parameterForeground": "#005cc5cc",
		//"editorInlayHint.typeBackground": "#dbedff4d",
		//"editorInlayHint.typeForeground": "#005cc5cc",
		//"editorLightBulb.foreground": "#ddb100",
		//"editorLightBulbAutoFix.foreground": "#007acc",
		//"editorLink.activeForeground": "#0000ff",
		//"editorMarkerNavigation.background": "#ffffff",
		//"editorMarkerNavigationError.background": "#cb2431",
		//"editorMarkerNavigationError.headerBackground": "#cb24311a",
		//"editorMarkerNavigationInfo.background": "#1a85ff",
		//"editorMarkerNavigationInfo.headerBackground": "#1a85ff1a",
		//"editorMarkerNavigationWarning.background": "#f9c513",
		//"editorMarkerNavigationWarning.headerBackground": "#f9c5131a",
		//"editorOverviewRuler.addedForeground": "#28a74599",
		//"editorOverviewRuler.bracketMatchForeground": "#a0a0a0",
		//"editorOverviewRuler.commonContentForeground": "#60606066",
		//"editorOverviewRuler.currentContentForeground": "#40c8ae80",
		//"editorOverviewRuler.deletedForeground": "#d73a4999",
		//"editorOverviewRuler.errorForeground": "#ff1212b3",
		//"editorOverviewRuler.findMatchForeground": "#d186167e",
		//"editorOverviewRuler.incomingContentForeground": "#40a6ff80",
		//"editorOverviewRuler.infoForeground": "#1a85ff",
		//"editorOverviewRuler.modifiedForeground": "#2188ff99",
		//"editorOverviewRuler.rangeHighlightForeground": "#007acc99",
		//"editorOverviewRuler.selectionHighlightForeground": "#a0a0a0cc",
		//"editorOverviewRuler.warningForeground": "#f9c513",
		//"editorOverviewRuler.wordHighlightForeground": "#a0a0a0cc",
		//"editorOverviewRuler.wordHighlightStrongForeground": "#c0a0c0cc",
		//"editorPane.background": "#ffffff",
		//"editorRuler.foreground": "#d3d3d3",
		//"editorStickyScroll.background": "#ffffff",
		//"editorStickyScrollHover.background": "#f0f0f0",
		//"editorSuggestWidget.background": "#f6f8fa",
		//"editorSuggestWidget.border": "#c8c8c8",
		//"editorSuggestWidget.focusHighlightForeground": "#0066bf",
		//"editorSuggestWidget.foreground": "#24292e",
		//"editorSuggestWidget.highlightForeground": "#0066bf",
		//"editorSuggestWidget.selectedBackground": "#e2e5e9",
		//"editorSuggestWidget.selectedForeground": "#2f363d",
		//"editorSuggestWidgetStatus.foreground": "#24292e80",
		//"editorUnicodeHighlight.background": "#cea33d14",
		//"editorUnicodeHighlight.border": "#cea33d",
		//"editorUnnecessaryCode.opacity": "#00000077",
		//"editorWidget.border": "#c8c8c8",
		//"editorWidget.foreground": "#444d56",
		//"extensionBadge.remoteBackground": "#2188ff",
		//"extensionBadge.remoteForeground": "#ffffff",
		//"extensionButton.prominentBackground": "#159739",
		//"extensionButton.prominentForeground": "#ffffff",
		//"extensionButton.prominentHoverBackground": "#138934",
		//"extensionIcon.preReleaseForeground": "#1d9271",
		//"extensionIcon.sponsorForeground": "#b51e78",
		//"extensionIcon.starForeground": "#df6100",
		//"extensionIcon.verifiedForeground": "#0366d6",
		//"gitDecoration.renamedResourceForeground": "#007100",
		//"gitDecoration.stageDeletedResourceForeground": "#ad0707",
		//"gitDecoration.stageModifiedResourceForeground": "#895503",
		//"icon.foreground": "#424242",
		//"inputOption.activeBackground": "#2188ff33",
		//"inputOption.activeBorder": "#007acc00",
		//"inputOption.activeForeground": "#000000",
		//"inputOption.hoverBackground": "#b8b8b850",
		//"inputValidation.errorBackground": "#f2dede",
		//"inputValidation.errorBorder": "#be1100",
		//"inputValidation.infoBackground": "#d6ecf2",
		//"inputValidation.infoBorder": "#007acc",
		//"inputValidation.warningBackground": "#f6f5d2",
		//"inputValidation.warningBorder": "#b89500",
		//"interactive.activeCodeBorder": "#1a85ff",
		//"interactive.inactiveCodeBorder": "#e8eaed",
		//"keybindingLabel.background": "#dddddd66",
		//"keybindingLabel.border": "#cccccc66",
		//"keybindingLabel.bottomBorder": "#bbbbbb66",
		//"keybindingLabel.foreground": "#555555",
		//"keybindingTable.headerBackground": "#444d560a",
		//"keybindingTable.rowsBackground": "#444d560a",
		//"list.deemphasizedForeground": "#8e8e90",
		//"list.dropBackground": "#d6ebff",
		//"list.errorForeground": "#b01011",
		//"list.filterMatchBackground": "#ffdf5d66",
		//"list.focusHighlightForeground": "#0066bf",
		//"list.focusOutline": "#2188ff",
		//"list.highlightForeground": "#0066bf",
		//"list.invalidItemForeground": "#b89500",
		//"list.warningForeground": "#855f00",
		//"listFilterWidget.background": "#f6f8fa",
		//"listFilterWidget.noMatchesOutline": "#be1100",
		//"listFilterWidget.outline": "#00000000",
		//"listFilterWidget.shadow": "#00000029",
		//"menu.background": "#fafbfc",
		//"menu.foreground": "#444d56",
		//"menu.selectionBackground": "#e2e5e9",
		//"menu.selectionForeground": "#2f363d",
		//"menu.separatorBackground": "#d4d4d4",
		//"menubar.selectionBackground": "#b8b8b850",
		//"menubar.selectionForeground": "#2f363d",
		//"merge.commonContentBackground": "#60606029",
		//"merge.commonHeaderBackground": "#60606066",
		//"merge.currentContentBackground": "#40c8ae33",
		//"merge.currentHeaderBackground": "#40c8ae80",
		//"merge.incomingContentBackground": "#40a6ff33",
		//"merge.incomingHeaderBackground": "#40a6ff80",
		//"mergeEditor.change.background": "#9bb95533",
		//"mergeEditor.change.word.background": "#9bb9551e",
		//"mergeEditor.conflict.handled.minimapOverViewRuler": "#adaca8ee",
		//"mergeEditor.conflict.handledFocused.border": "#c1c1c1cc",
		//"mergeEditor.conflict.handledUnfocused.border": "#86868649",
		//"mergeEditor.conflict.unhandled.minimapOverViewRuler": "#fcba03",
		//"mergeEditor.conflict.unhandledFocused.border": "#ffa600",
		//"mergeEditor.conflict.unhandledUnfocused.border": "#ffa6007a",
		//"minimap.errorHighlight": "#ff1212b3",
		//"minimap.findMatchHighlight": "#d18616",
		//"minimap.foregroundOpacity": "#000000",
		//"minimap.selectionHighlight": "#add6ff",
		//"minimap.selectionOccurrenceHighlight": "#c9c9c9",
		//"minimap.warningHighlight": "#f9c513",
		//"minimapGutter.addedBackground": "#28a745",
		//"minimapGutter.deletedBackground": "#d73a49",
		//"minimapGutter.modifiedBackground": "#2188ff",
		//"minimapSlider.activeBackground": "#959da544",
		//"minimapSlider.background": "#959da51a",
		//"minimapSlider.hoverBackground": "#959da522",
		//"notebook.cellBorderColor": "#e8eaed",
		//"notebook.cellEditorBackground": "#f6f8fa",
		//"notebook.cellInsertionIndicator": "#2188ff",
		//"notebook.cellStatusBarItemHoverBackground": "#00000014",
		//"notebook.cellToolbarSeparator": "#80808059",
		//"notebook.editorBackground": "#ffffff",
		//"notebook.focusedCellBorder": "#2188ff",
		//"notebook.focusedEditorBorder": "#2188ff",
		//"notebook.inactiveFocusedCellBorder": "#e8eaed",
		//"notebook.selectedCellBackground": "#e8eaed",
		//"notebook.selectedCellBorder": "#e8eaed",
		//"notebook.symbolHighlightBackground": "#fdff0033",
		//"notebookScrollbarSlider.activeBackground": "#959da588",
		//"notebookScrollbarSlider.background": "#959da533",
		//"notebookScrollbarSlider.hoverBackground": "#959da544",
		//"notebookStatusErrorIcon.foreground": "#cb2431",
		//"notebookStatusRunningIcon.foreground": "#444d56",
		//"notebookStatusSuccessIcon.foreground": "#388a34",
		//"notificationLink.foreground": "#0366d6",
		//"panel.dropBorder": "#2f363d",
		//"panelSection.border": "#e1e4e8",
		//"panelSection.dropBackground": "#2677cb2e",
		//"panelSectionHeader.background": "#80808033",
		//"peekView.border": "#1a85ff",
		//"peekViewEditor.background": "#f2f8fc",
		//"peekViewEditor.matchHighlightBackground": "#f5d802de",
		//"peekViewEditorGutter.background": "#f2f8fc",
		//"peekViewResult.background": "#f3f3f3",
		//"peekViewResult.fileForeground": "#1e1e1e",
		//"peekViewResult.lineForeground": "#646465",
		//"peekViewResult.matchHighlightBackground": "#ea5c004d",
		//"peekViewResult.selectionBackground": "#3399ff33",
		//"peekViewResult.selectionForeground": "#6c6c6c",
		//"peekViewTitle.background": "#1a85ff1a",
		//"peekViewTitleDescription.foreground": "#616161",
		//"peekViewTitleLabel.foreground": "#000000",
		//"ports.iconRunningProcessForeground": "#ffffff",
		//"problemsErrorIcon.foreground": "#cb2431",
		//"problemsInfoIcon.foreground": "#1a85ff",
		//"problemsWarningIcon.foreground": "#f9c513",
		//"quickInputList.focusBackground": "#e2e5e9",
		//"quickInputList.focusForeground": "#2f363d",
		//"quickInputTitle.background": "#0000000f",
		//"sash.hoverBorder": "#2188ff",
		//"scm.providerBorder": "#c8c8c8",
		//"searchEditor.findMatchBackground": "#ffdf5d43",
		//"searchEditor.textInputBorder": "#e1e4e8",
		//"settings.checkboxBackground": "#fafbfc",
		//"settings.checkboxBorder": "#d1d5da",
		//"settings.checkboxForeground": "#2f363d",
		//"settings.dropdownBackground": "#fafbfc",
		//"settings.dropdownBorder": "#e1e4e8",
		//"settings.dropdownForeground": "#2f363d",
		//"settings.dropdownListBorder": "#c8c8c8",
		//"settings.focusedRowBackground": "#ebf0f499",
		//"settings.focusedRowBorder": "#0000001f",
		//"settings.headerBorder": "#e1e4e8",
		//"settings.numberInputBackground": "#fafbfc",
		//"settings.numberInputBorder": "#e1e4e8",
		//"settings.numberInputForeground": "#2f363d",
		//"settings.rowHoverBackground": "#ebf0f44d",
		//"settings.sashBorder": "#e1e4e8",
		//"settings.textInputBackground": "#fafbfc",
		//"settings.textInputBorder": "#e1e4e8",
		//"settings.textInputForeground": "#2f363d",
		//"sideBar.dropBackground": "#2677cb2e",
		//"sideBySideEditor.horizontalBorder": "#e1e4e8",
		//"sideBySideEditor.verticalBorder": "#e1e4e8",
		//"statusBar.debuggingBorder": "#e1e4e8",
		//"statusBar.focusBorder": "#586069",
		//"statusBar.noFolderBorder": "#e1e4e8",
		//"statusBar.noFolderForeground": "#586069",
		//"statusBarItem.activeBackground": "#ffffff2e",
		//"statusBarItem.compactHoverBackground": "#ffffff33",
		//"statusBarItem.errorBackground": "#7a161e",
		//"statusBarItem.errorForeground": "#ffffff",
		//"statusBarItem.focusBorder": "#586069",
		//"statusBarItem.hoverBackground": "#ffffff1f",
		//"statusBarItem.prominentForeground": "#586069",
		//"statusBarItem.prominentHoverBackground": "#0000004d",
		//"statusBarItem.warningBackground": "#9d7904",
		//"statusBarItem.warningForeground": "#ffffff",
		//"symbolIcon.arrayForeground": "#444d56",
		//"symbolIcon.booleanForeground": "#444d56",
		//"symbolIcon.classForeground": "#d67e00",
		//"symbolIcon.colorForeground": "#444d56",
		//"symbolIcon.constantForeground": "#444d56",
		//"symbolIcon.constructorForeground": "#652d90",
		//"symbolIcon.enumeratorForeground": "#d67e00",
		//"symbolIcon.enumeratorMemberForeground": "#007acc",
		//"symbolIcon.eventForeground": "#d67e00",
		//"symbolIcon.fieldForeground": "#007acc",
		//"symbolIcon.fileForeground": "#444d56",
		//"symbolIcon.folderForeground": "#444d56",
		//"symbolIcon.functionForeground": "#652d90",
		//"symbolIcon.interfaceForeground": "#007acc",
		//"symbolIcon.keyForeground": "#444d56",
		//"symbolIcon.keywordForeground": "#444d56",
		//"symbolIcon.methodForeground": "#652d90",
		//"symbolIcon.moduleForeground": "#444d56",
		//"symbolIcon.namespaceForeground": "#444d56",
		//"symbolIcon.nullForeground": "#444d56",
		//"symbolIcon.numberForeground": "#444d56",
		//"symbolIcon.objectForeground": "#444d56",
		//"symbolIcon.operatorForeground": "#444d56",
		//"symbolIcon.packageForeground": "#444d56",
		//"symbolIcon.propertyForeground": "#444d56",
		//"symbolIcon.referenceForeground": "#444d56",
		//"symbolIcon.snippetForeground": "#444d56",
		//"symbolIcon.stringForeground": "#444d56",
		//"symbolIcon.structForeground": "#444d56",
		//"symbolIcon.textForeground": "#444d56",
		//"symbolIcon.typeParameterForeground": "#444d56",
		//"symbolIcon.unitForeground": "#444d56",
		//"symbolIcon.variableForeground": "#007acc",
		//"tab.activeModifiedBorder": "#33aaee",
		//"tab.inactiveModifiedBorder": "#33aaee80",
		//"tab.lastPinnedBorder": "#e1e4e8",
		//"tab.unfocusedActiveBackground": "#ffffff",
		//"tab.unfocusedActiveForeground": "#2f363db3",
		//"tab.unfocusedActiveModifiedBorder": "#33aaeeb3",
		//"tab.unfocusedInactiveBackground": "#f6f8fa",
		//"tab.unfocusedInactiveForeground": "#6a737d80",
		//"tab.unfocusedInactiveModifiedBorder": "#33aaee40",
		//"terminal.border": "#e1e4e8",
		//"terminal.dropBackground": "#2677cb2e",
		//"terminal.findMatchBackground": "#ffdf5d",
		//"terminal.findMatchHighlightBackground": "#ffdf5d66",
		//"terminal.selectionBackground": "#0366d625",
		//"terminalCommandDecoration.defaultBackground": "#00000040",
		//"terminalCommandDecoration.errorBackground": "#e51400",
		//"terminalCommandDecoration.successBackground": "#2090d3",
		//"terminalOverviewRuler.cursorForeground": "#a0a0a0cc",
		//"terminalOverviewRuler.findMatchForeground": "#d186167e",
		//"testing.iconErrored": "#f14c4c",
		//"testing.iconFailed": "#f14c4c",
		//"testing.iconPassed": "#73c991",
		//"testing.iconQueued": "#cca700",
		//"testing.iconSkipped": "#848484",
		//"testing.iconUnset": "#848484",
		//"testing.message.error.decorationForeground": "#cb2431",
		//"testing.message.error.lineBackground": "#ff000033",
		//"testing.message.info.decorationForeground": "#24292e80",
		//"testing.peekBorder": "#cb2431",
		//"testing.peekHeaderBackground": "#cb24311a",
		//"testing.runAction": "#73c991",
		//"toolbar.activeBackground": "#a6a6a650",
		//"toolbar.hoverBackground": "#b8b8b850",
		//"tree.tableColumnsBorder": "#61616120",
		//"tree.tableOddRowsBackground": "#444d560a",
		//"welcomePage.progress.background": "#fafbfc",
		//"welcomePage.progress.foreground": "#0366d6",
		//"welcomePage.tileBackground": "#f6f8fa",
		//"welcomePage.tileHoverBackground": "#d6dfe8",
		//"welcomePage.tileShadow": "#00000029",
		//"widget.shadow": "#00000029",
		//"activityBar.activeBackground": null,
		//"activityBar.activeFocusBorder": null,
		//"button.border": null,
		//"commandCenter.background": null,
		//"contrastActiveBorder": null,
		//"contrastBorder": null,
		//"debugToolBar.border": null,
		//"diffEditor.border": null,
		//"diffEditor.insertedTextBorder": null,
		//"diffEditor.removedTextBorder": null,
		//"diffEditorGutter.insertedLineBackground": null,
		//"diffEditorGutter.removedLineBackground": null,
		//"diffEditorOverview.insertedForeground": null,
		//"diffEditorOverview.removedForeground": null,
		//"editor.findMatchBorder": null,
		//"editor.findMatchHighlightBorder": null,
		//"editor.findRangeHighlightBorder": null,
		//"editor.rangeHighlightBorder": null,
		//"editor.selectionForeground": null,
		//"editor.snippetFinalTabstopHighlightBackground": null,
		//"editor.snippetTabstopHighlightBorder": null,
		//"editor.symbolHighlightBorder": null,
		//"editorCursor.background": null,
		//"editorError.background": null,
		//"editorError.border": null,
		//"editorGhostText.background": null,
		//"editorGhostText.border": null,
		//"editorGroup.dropIntoPromptBorder": null,
		//"editorGroup.emptyBackground": null,
		//"editorGroup.focusedEmptyBorder": null,
		//"editorGroupHeader.border": null,
		//"editorHint.border": null,
		//"editorInfo.background": null,
		//"editorInfo.border": null,
		//"editorOverviewRuler.background": null,
		//"editorSuggestWidget.selectedIconForeground": null,
		//"editorUnnecessaryCode.border": null,
		//"editorWarning.background": null,
		//"editorWarning.border": null,
		//"editorWidget.resizeBorder": null,
		//"inputValidation.errorForeground": null,
		//"inputValidation.infoForeground": null,
		//"inputValidation.warningForeground": null,
		//"list.activeSelectionIconForeground": null,
		//"list.filterMatchBorder": null,
		//"list.focusAndSelectionOutline": null,
		//"list.focusForeground": null,
		//"list.inactiveFocusOutline": null,
		//"list.inactiveSelectionIconForeground": null,
		//"menu.border": null,
		//"menu.selectionBorder": null,
		//"menubar.selectionBorder": null,
		//"merge.border": null,
		//"minimap.background": null,
		//"notebook.cellHoverBackground": null,
		//"notebook.focusedCellBackground": null,
		//"notebook.inactiveSelectedCellBorder": null,
		//"notebook.outputContainerBackgroundColor": null,
		//"notebook.outputContainerBorderColor": null,
		//"notificationCenter.border": null,
		//"notificationToast.border": null,
		//"panelSectionHeader.border": null,
		//"panelSectionHeader.foreground": null,
		//"peekViewEditor.matchHighlightBorder": null,
		//"quickInput.list.focusBackground": null,
		//"quickInputList.focusIconForeground": null,
		//"searchEditor.findMatchBorder": null,
		//"selection.background": null,
		//"statusBarItem.settingsProfilesBackground": null,
		//"statusBarItem.settingsProfilesForeground": null,
		//"tab.hoverBorder": null,
		//"tab.hoverForeground": null,
		//"tab.unfocusedHoverBorder": null,
		//"tab.unfocusedHoverForeground": null,
		//"terminal.background": null,
		//"terminal.findMatchBorder": null,
		//"terminal.findMatchHighlightBorder": null,
		//"terminal.selectionForeground": null,
		//"testing.message.info.lineBackground": null,
		//"toolbar.hoverOutline": null,
		//"walkThrough.embeddedEditorBackground": null,
		//"welcomePage.background": null,
		//"window.activeBorder": null,
		//"window.inactiveBorder": null
	},
	"tokenColors": [
		{
			"scope": [
				"comment",
				"punctuation.definition.comment",
				"string.comment"
			],
			"settings": {
				"foreground": "#6A737D"
			}
		},
		{
			"scope": [
				"constant",
				"entity.name.constant",
				"variable.other.constant",
				"variable.other.enummember",
				"variable.language"
			],
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": [
				"entity",
				"entity.name"
			],
			"settings": {
				"foreground": "#6F42C1"
			}
		},
		{
			"scope": "variable.parameter.function",
			"settings": {
				"foreground": "#24292E"
			}
		},
		{
			"scope": "entity.name.tag",
			"settings": {
				"foreground": "#22863A"
			}
		},
		{
			"scope": "keyword",
			"settings": {
				"foreground": "#D73A49"
			}
		},
		{
			"scope": [
				"storage",
				"storage.type"
			],
			"settings": {
				"foreground": "#D73A49"
			}
		},
		{
			"scope": [
				"storage.modifier.package",
				"storage.modifier.import",
				"storage.type.java"
			],
			"settings": {
				"foreground": "#24292E"
			}
		},
		{
			"scope": [
				"string",
				"punctuation.definition.string",
				"string punctuation.section.embedded source"
			],
			"settings": {
				"foreground": "#032F62"
			}
		},
		{
			"scope": "support",
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": "meta.property-name",
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": "variable",
			"settings": {
				"foreground": "#E36209"
			}
		},
		{
			"scope": "variable.other",
			"settings": {
				"foreground": "#24292E"
			}
		},
		{
			"scope": "invalid.broken",
			"settings": {
				"foreground": "#B31D28",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "invalid.deprecated",
			"settings": {
				"foreground": "#B31D28",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "invalid.illegal",
			"settings": {
				"foreground": "#B31D28",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "invalid.unimplemented",
			"settings": {
				"foreground": "#B31D28",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "carriage-return",
			"settings": {
				"foreground": "#FAFBFC",
				"background": "#D73A49",
				"fontStyle": "italic underline"
			}
		},
		{
			"scope": "message.error",
			"settings": {
				"foreground": "#B31D28"
			}
		},
		{
			"scope": "string variable",
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": [
				"source.regexp",
				"string.regexp"
			],
			"settings": {
				"foreground": "#032F62"
			}
		},
		{
			"scope": [
				"string.regexp.character-class",
				"string.regexp constant.character.escape",
				"string.regexp source.ruby.embedded",
				"string.regexp string.regexp.arbitrary-repitition"
			],
			"settings": {
				"foreground": "#032F62"
			}
		},
		{
			"scope": "string.regexp constant.character.escape",
			"settings": {
				"foreground": "#22863A",
				"fontStyle": "bold"
			}
		},
		{
			"scope": "support.constant",
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": "support.variable",
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": "meta.module-reference",
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": "punctuation.definition.list.begin.markdown",
			"settings": {
				"foreground": "#E36209"
			}
		},
		{
			"scope": [
				"markup.heading",
				"markup.heading entity.name"
			],
			"settings": {
				"foreground": "#005CC5",
				"fontStyle": "bold"
			}
		},
		{
			"scope": "markup.quote",
			"settings": {
				"foreground": "#22863A"
			}
		},
		{
			"scope": "markup.italic",
			"settings": {
				"foreground": "#24292E",
				"fontStyle": "italic"
			}
		},
		{
			"scope": "markup.bold",
			"settings": {
				"foreground": "#24292E",
				"fontStyle": "bold"
			}
		},
		{
			"scope": [
				"markup.underline"
			],
			"settings": {
				"fontStyle": "underline"
			}
		},
		{
			"scope": [
				"markup.strikethrough"
			],
			"settings": {
				"fontStyle": "strikethrough"
			}
		},
		{
			"scope": "markup.inline.raw",
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": [
				"markup.deleted",
				"meta.diff.header.from-file",
				"punctuation.definition.deleted"
			],
			"settings": {
				"foreground": "#B31D28",
				"background": "#FFEEF0"
			}
		},
		{
			"scope": [
				"markup.inserted",
				"meta.diff.header.to-file",
				"punctuation.definition.inserted"
			],
			"settings": {
				"foreground": "#22863A",
				"background": "#F0FFF4"
			}
		},
		{
			"scope": [
				"markup.changed",
				"punctuation.definition.changed"
			],
			"settings": {
				"foreground": "#E36209",
				"background": "#FFEBDA"
			}
		},
		{
			"scope": [
				"markup.ignored",
				"markup.untracked"
			],
			"settings": {
				"foreground": "#F6F8FA",
				"background": "#005CC5"
			}
		},
		{
			"scope": "meta.diff.range",
			"settings": {
				"foreground": "#6F42C1",
				"fontStyle": "bold"
			}
		},
		{
			"scope": "meta.diff.header",
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": "meta.separator",
			"settings": {
				"foreground": "#005CC5",
				"fontStyle": "bold"
			}
		},
		{
			"scope": "meta.output",
			"settings": {
				"foreground": "#005CC5"
			}
		},
		{
			"scope": [
				"brackethighlighter.tag",
				"brackethighlighter.curly",
				"brackethighlighter.round",
				"brackethighlighter.square",
				"brackethighlighter.angle",
				"brackethighlighter.quote"
			],
			"settings": {
				"foreground": "#586069"
			}
		},
		{
			"scope": "brackethighlighter.unmatched",
			"settings": {
				"foreground": "#B31D28"
			}
		},
		{
			"scope": [
				"constant.other.reference.link",
				"string.other.link"
			],
			"settings": {
				"foreground": "#032F62",
				"fontStyle": "underline"
			}
		},
		{
			"scope": "token.info-token",
			"settings": {
				"foreground": "#316BCD"
			}
		},
		{
			"scope": "token.warn-token",
			"settings": {
				"foreground": "#CD9731"
			}
		},
		{
			"scope": "token.error-token",
			"settings": {
				"foreground": "#CD3131"
			}
		},
		{
			"scope": "token.debug-token",
			"settings": {
				"foreground": "#800080"
			}
		}
	]
}