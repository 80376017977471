import * as ChakraUI from "@chakra-ui/react";
import React from "react";

export interface BadgeProps {
  text?: React.ReactNode;
}

const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(({
  text,
  ...rest
}, ref) => {
  return (
    <ChakraUI.Badge
      ref={ref}
      {...rest}
    >
      {text}
    </ChakraUI.Badge>
  );
});

export default Badge;
