export type Collections = Record<string, Record<string, unknown>[]>;

export type ScriptItem = {
  label: string;
  query: string;
  collections: Collections;
}

const collections = {
  books: [
    {
      _id: {
        $oid: "630b6a9b158e2597b321e821"
      },
      title: "The Mist",
      year: 1980,
      author: {
        $oid: "630b6a9fa0a97047561f9fa8"
      }
    },
    {
      _id: {
        $oid: "630b6a9cf6905294637b3d70"
      },
      title: "The Dark Tower",
      year: 2003,
      author: {
        $oid: "630b6a9fa0a97047561f9fa8"
      }
    },
    {
      _id: {
        $oid: "630b6a9c725f74bc9c9d8c6e"
      },
      title: "Harry Potter and the Sorcerer's Stone",
      year: 1997,
      author: {
        $oid: "630b6aa24269e5e856119413"
      }
    },
    {
      _id: {
        $oid: "630b6a9ed8657cf14fc515ad"
      },
      title: "Harry Potter and the Chamber of Secrets",
      year: 1998,
      author: {
        $oid: "630b6aa24269e5e856119413"
      }
    },
    {
      _id: {
        $oid: "630b6a9ebc650571fe725477"
      },
      title: "Harry Potter and the Prisoner of Azkaban",
      year: 1999,
      author: {
        $oid: "630b6aa24269e5e856119413"
      }
    },
  ],
  authors: [
    {
      _id: {
        $oid: "630b6a9fa0a97047561f9fa8"
      },
      name: "Stephen King",
    },
    {
      _id: {
        $oid: "630b6aa24269e5e856119413"
      },
      name: "J.K. Rowling",
    }
  ]
}

export const  SCRIPTS: ScriptItem[] = [
  {
    label: 'find',
    query: `db.books.find().pretty()`,
    collections,
  },

  {
    label: 'find with match',
    query: `db.books.find({ title: "The Mist" }).pretty()`,
    collections,
  },

  {
    label: 'find with regex match',
    query: `db.books.find({ title: /harry potter/i }).pretty()`,
    collections,
  },

  {
    label: 'sort asc',
    query: `db.books.find().sort({ year: 1 }).pretty()`,
    collections,
  },

  {
    label: 'sort desc',
    query: `db.books.find().sort({ year: -1 }).pretty()`,
    collections,
  },

  {
    label: 'limit',
    query: `db.books.find().limit(3).pretty()`,
    collections,
  },

  {
    label: 'limit and skip',
    query: `db.books.find().limit(3).skip(1).pretty()`,
    collections,
  },


  {
    label: 'findOne',
    query: `db.books.findOne()`,
    collections,
  },

  {
    label: 'findOne with match',
    query: `db.books.findOne({ title: "The Mist" })`,
    collections,
  },

  {
    label: 'findOne with regex match',
    query: `db.books.findOne({ title: /harry potter/i })`,
    collections,
  },

  {
    label: 'insertOne',
    query: `db.books.insertOne({
  title: "Dreamcatcher",
  year: 2001,
  author: { $oid: "630b6a9fa0a97047561f9fa8" }
})`,
    collections,
  },


  {
    label: 'updateOne',
    query: `db.books.updateOne({
  title: "The Mist",
}, {
  $set: {
    year: 2007
  }
})`,
    collections,
  },

  {
    label: 'deleteMany',
    query: `db.books.deleteMany({})`,
    collections,
  },

  {
    label: 'deleteMany with match',
    query: `db.books.deleteMany({ title: "The Mist" })`,
    collections,
  },

  {
    label: 'deleteMany with regex',
    query: `db.books.deleteMany({ title: /harry potter/i })`,
    collections,
  },

  {
    label: 'deleteOne',
    query: `db.books.deleteOne({ title: "The Mist" })`,
    collections,
  },
];

export const DEFAULT_SCRIPT = SCRIPTS[0];