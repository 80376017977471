import * as ChakraUI from "@chakra-ui/react";

const COLOR = {
  primary: 'blue.500',
} as const;

const EMPTY_COLOR = {
  primary: 'gray.300',
} as const;

const SIZE = {
  small: 'sm',
  medium: 'md',
  large: 'lg',
  huge: 'xl',
} as const;

export interface SpinnerProps {
  color?: keyof typeof COLOR;
  size?: keyof typeof SIZE;
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 'medium',
  color = 'primary',
}) => {
  return (
    <ChakraUI.Spinner
      size={SIZE[size]}
      speed="0.65s"
      emptyColor={EMPTY_COLOR[color]}
      color={COLOR[color]}
    />
  );
};

export default Spinner;
