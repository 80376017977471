import * as ChakraUI from "@chakra-ui/react";
import Card from "../Card";

const TABS_STYLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
}

const TAB_PANELS_STYLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  flex: '1',
}

const TAB_PANEL_STYLE: React.CSSProperties = {
  flex: '1',
  padding: 0,
}

export type PanelItem = {
  label: string;
  content: React.ReactNode;
}

export interface PanelProps {
  panels: PanelItem[];
}

const Panel: React.FC<PanelProps> = ({
  panels,
}) => {
  return (
    <Card style={{ flex: 1 }}>
      <ChakraUI.Tabs style={TABS_STYLE}>
        <ChakraUI.TabList>
          {panels.map((panel, index) => (
            <ChakraUI.Tab key={index}>{panel.label}</ChakraUI.Tab>
          ))}
        </ChakraUI.TabList>

        <ChakraUI.TabPanels style={TAB_PANELS_STYLE}>
          {panels.map((panel, index) => (
            <ChakraUI.TabPanel key={index} style={TAB_PANEL_STYLE}>
              {panel.content}
            </ChakraUI.TabPanel>
          ))}
        </ChakraUI.TabPanels>
      </ChakraUI.Tabs>
    </Card>
  );
};

export default Panel;
