import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {"fetchPolicy":"network-only"} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject: any;
};

export type EmptyResponse = {
  __typename?: 'EmptyResponse';
  _empty?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  runQuery: Result;
};


export type MutationRunQueryArgs = {
  collections: Scalars['JSONObject'];
  query: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  ping: Scalars['String'];
};


export type QueryPingArgs = {
  delay?: InputMaybe<Scalars['Int']>;
};

export type Result = {
  __typename?: 'Result';
  collections: Scalars['JSONObject'];
  executionTime: Scalars['Int'];
  response: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  _empty?: Maybe<Scalars['String']>;
};

export type RunQueryMutationVariables = Exact<{
  collections: Scalars['JSONObject'];
  query: Scalars['String'];
}>;


export type RunQueryMutation = { __typename?: 'Mutation', runQuery: { __typename?: 'Result', response: string, collections: any, executionTime: number } };


export const RunQueryDocument = gql`
    mutation runQuery($collections: JSONObject!, $query: String!) {
  runQuery(collections: $collections, query: $query) {
    response
    collections
    executionTime
  }
}
    `;
export type RunQueryMutationFn = Apollo.MutationFunction<RunQueryMutation, RunQueryMutationVariables>;

/**
 * __useRunQueryMutation__
 *
 * To run a mutation, you first call `useRunQueryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunQueryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runQueryMutation, { data, loading, error }] = useRunQueryMutation({
 *   variables: {
 *      collections: // value for 'collections'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useRunQueryMutation(baseOptions?: Apollo.MutationHookOptions<RunQueryMutation, RunQueryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RunQueryMutation, RunQueryMutationVariables>(RunQueryDocument, options);
      }
export type RunQueryMutationHookResult = ReturnType<typeof useRunQueryMutation>;
export type RunQueryMutationResult = Apollo.MutationResult<RunQueryMutation>;
export type RunQueryMutationOptions = Apollo.BaseMutationOptions<RunQueryMutation, RunQueryMutationVariables>;