import {
  Container,
  Sidebar,
  Editor,
  Result,
} from './styles';

export interface AppLayoutProps {
  sidebar: React.ReactNode;
  editor: React.ReactNode;
  result: React.ReactNode;
}

const AppLayout: React.FC<AppLayoutProps> = ({
  sidebar,
  editor,
  result,
}) => {
  return (
    <Container>
      <Sidebar>{sidebar}</Sidebar>
      <Editor>{editor}</Editor>
      <Result>{result}</Result>
    </Container>
  );
};

export default AppLayout;
