import * as ChakraUI from "@chakra-ui/react";

const PLACEMENT = {
  top: 'top',
} as const;

export interface TooltipProps {
  placement?: keyof typeof PLACEMENT;
  label?: React.ReactNode;
  children?: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({
  placement = 'top',
  label,
  children,
}) => {
  return (
    <ChakraUI.Tooltip
      placement={PLACEMENT[placement]}
      label={label}
      hasArrow
    >
      {children}
    </ChakraUI.Tooltip>
  );
};

export default Tooltip;
