import * as ChakraUI from "@chakra-ui/react";

export interface CardProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Card: React.FC<CardProps> = ({
  children,
  style,
}) => {
  return (
    <ChakraUI.Box
      boxShadow="xs"
      rounded="md"
      bg="white"
      style={style}
    >
      {children}
    </ChakraUI.Box>
  );
};

export default Card;
