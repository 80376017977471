import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  min-width: 60rem;
  height: 100vh;
  padding: 0.5rem;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  height: 100%;
  padding: 0.5rem;
`;

export const Editor = styled.div`
  display: flex;
  flex-direction: column;
  width: calc((100% - 20rem) / 2);
  height: 100%;
  padding: 0.5rem;
`;


export const Result = styled.div`
  display: flex;
  flex-direction: column;
  width: calc((100% - 20rem) / 2);
  height: 100%;
  padding: 0.5rem;
`;