import Button from "../../components/atoms/Button";
import Spinner from "../../components/atoms/Spinner";
import CodeEditor from "../../components/atoms/CodeEditor";
import { useAppService } from "./service";
import Tooltip from "../../components/atoms/Tooltip";
import AppLayout from "../../components/layout/AppLayout";
import Menu from "../../components/molecules/Menu";
import Panel from "../../components/atoms/Panel";
import Badge from "../../components/atoms/Badge";

const App = () => {
  const {
    isLoading,
    menuItems,
    query,
    result,
    error,
    totalExecutionTime,
    collections,
    onCollectionsChange,
    onQueryChange,
    onScriptChange,
    onRun,
  } = useAppService();

  return (
    <AppLayout
      sidebar={(
        <div
          style={{
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Menu
            items={menuItems}
            onClickItem={(menuItem) => onScriptChange(menuItem.data)}
          />
        </div>
      )}

      editor={(
        <div
          style={{
            display: 'flex',
            position: 'relative',
            height: '100%',
          }}
        >
          <Panel
            panels={[
              {
                label: 'Query',
                content: (
                  <>
                    <CodeEditor
                      value={query}
                      language="javascript"
                      onChange={onQueryChange}
                      onRun={onRun}
                    />
                  </>
                )
              }
            ]}
          />

          <div
            style={{
              position: 'absolute',
              top: "0.25rem",
              right: "0.25rem",
            }}
          >
            <Tooltip label="Run Query">
              <Button
                color="primary"
                size="tiny"
                text="Run query"
                loadingText="Running"
                isLoading={isLoading}
                onClick={onRun}
              />
            </Tooltip>
          </div>
        </div>
      )}

      result={(
        <div
          style={{
            display: 'flex',
            position: 'relative',
            height: '100%',
          }}
        >
          <Panel
            panels={[
              {
                label: 'Response',
                content: (
                  <>
                    {isLoading && (
                      <div style={{
                        display: 'flex',
                        flex: 1,
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}>
                        <Spinner size="huge" />
                      </div>
                    )}

                    {!isLoading && !error && result && (
                      <CodeEditor
                        readOnly
                        language="json"
                        value={result.response}
                      />
                    )}

                    {!isLoading && error && (
                      <CodeEditor
                        readOnly
                        language="json"
                        value={error}
                      />
                    )}
                  </>
                )
              },
              {
                label: 'Data',
                content: (
                  <CodeEditor
                    value={JSON.stringify(collections, null, 2)}
                    language="json"
                    onChange={(value) => onCollectionsChange(JSON.parse(value))}
                  />
                )
              }
            ]}
          />

          {!isLoading && result && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
                height: '2.5rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.5rem'
              }}
            >
              <Tooltip
                label={(
                  <>
                    <div>Query time: {result.executionTime}ms</div>
                    <div>Total time: {totalExecutionTime}ms</div>
                  </>
                )}
              >
                <Badge
                  text={`${result.executionTime}ms`}
                />
              </Tooltip>
            </div>
          )}
          
        </div>
      )}
    />
  );
}

export default App;
