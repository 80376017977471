import { useCallback, useEffect, useMemo } from "react";
import Editor, { BeforeMount, OnMount, useMonaco } from "@monaco-editor/react";

import themeGithubDark from './themes/github-dark-vs';
import themeGithubLight from './themes/github-light-vs';

const themes = [
  { name: 'github-dark', theme: themeGithubDark },
  { name: 'github-light', theme: themeGithubLight },
];

const transformTheme = (theme: any): any => ({
  base: theme.type === 'dark' ? 'vs-dark' : 'vs',
  inherit: true,
  colors: theme.colors,
  rules: theme.tokenColors.reduce((rules: any[], data: any) => {
    const scopes = Array.isArray(data.scope) ? data.scope : [data.scope];

    scopes.forEach((scope: string) => {
      rules.push({
        token: scope,
        ...data.settings,
      });
    });

    return rules;
  }, []),
})

export interface CodeEditorProps {
  language?: 'json' | 'javascript';
  value?: string;
  defaultValue?: string;
  readOnly?: boolean;
  onChange?: (value: string) => void;
  onRun?: () => void;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  language,
  readOnly,
  value,
  defaultValue,
  onChange,
  onRun,
}) => {
  const options = useMemo(() => ({
    readOnly,
  }), [readOnly])

  const onEditorChange = (value: string = '') => {
    if (onChange) {
      onChange(value);
    }
  }

  const onEditorBeforeMount: BeforeMount = useCallback((monaco) => {
    themes.forEach(({ name, theme }) => {
      monaco.editor.defineTheme(name, transformTheme(theme));
    });

    monaco.editor.setTheme('github-light');
  }, []);

  const onEditorMount: OnMount = useCallback((editor, monaco) => {
    editor.addAction({
      id: 'run-query',
      label: 'Run query',
      keybindings: [
        monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter,
      ],
      run: function () {
        if (onRun) {
          onRun();
        }
      }
    });
  }, [onRun]);

  return (
   <Editor
     height="100%"
     options={options}
     defaultLanguage={language}
     value={value}
     defaultValue={defaultValue}
     beforeMount={onEditorBeforeMount}
     onMount={onEditorMount}
     onChange={onEditorChange}
   />
  );
};

export default CodeEditor;
