import React from "react";
import * as ChakraUI from "@chakra-ui/react";
import Button from "../../atoms/Button";

export type MenuItem = {
  label: string;
  isSelected?: boolean;
  data?: any;
}

export interface MenuProps {
  items: MenuItem[];
  onClickItem?: (item: MenuItem) => void;
}

const Menu: React.FC<MenuProps> = ({
  items,
  onClickItem,
}) => {
  return (
    <ChakraUI.Stack>
      {items.map((item, index) => (
        <Button
          key={index}
          color={item.isSelected ? 'primary' : 'gray'}
          text={item.label}
          onClick={() => onClickItem?.(item)}
        />
      ))}
    </ChakraUI.Stack>
  );
};

export default Menu;
