import { useMemo, useState } from "react";
import { MenuItem } from "../../components/molecules/Menu";

import { useRunQueryMutation } from "../../graphql/generated";
import { Collections, ScriptItem, SCRIPTS, DEFAULT_SCRIPT } from "./constants";

type Result = {
  response: string;
  collections: Collections;
  executionTime: number;
}

export const useAppService = () => {
  const [script, setScript] = useState(DEFAULT_SCRIPT);
  const [collections, setCollections] = useState<Collections>(DEFAULT_SCRIPT.collections);

  const [totalExecutionTime, setTotalExecutionTime] = useState<number>();
  const [isLoading,  setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>(DEFAULT_SCRIPT.query);
  const [result, setResult] = useState<Result>();
  const [error, setError] = useState<string>();

  const [runQuery] = useRunQueryMutation({
    variables: {
      collections,
      query,
    }
  });

  const onScriptChange = (script: ScriptItem) => {
    setScript(script);
    setQuery(script.query);
    setCollections(script.collections);
    setResult(undefined);
    setError(undefined);
    setTotalExecutionTime(undefined);
    setLoading(false);
  }

  const onRun = async () => {
    setLoading(true);

    try {
      const t = Date.now();
      const response = await runQuery();
      const totalExecutionTime = Date.now() - t;
      setTotalExecutionTime(totalExecutionTime)

      if (response.data) {
        setResult(response.data.runQuery);
        setCollections(response.data.runQuery.collections);
        setError(undefined);
      } else {
        setError(JSON.stringify(response.errors ?? 'Unknown error', null, 2));
      }

    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  const onCollectionsChange = (collections: Collections) => {
    setCollections(collections);
  }

  const onQueryChange = (query: string) => {
    setQuery(query);
  }

  const menuItems = useMemo(() => (
    SCRIPTS.map<MenuItem>(scriptItem => ({
      label: scriptItem.label,
      isSelected: scriptItem.label === script.label,
      data: scriptItem,
    }))
  ), [script]);

  return {
    isLoading,
    collections,
    query,
    result,
    error,
    totalExecutionTime,
    menuItems,
    onRun,
    onCollectionsChange,
    onQueryChange,
    onScriptChange,
  }
}
