import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import environment from './enviroment';

export const createApolloClient = () => {
  const client = new ApolloClient({
    uri: environment.graphqlUrl,
    cache: new InMemoryCache(),
  });

  return client;
}