import * as ChakraUI from "@chakra-ui/react";
import React from "react";

const VARIANT = {
  solid: 'solid',
  outline: 'outline',
  ghost: 'ghost',
} as const;

const COLOR = {
  primary: 'blue',
  gray: 'gray',
} as const;

const SIZE = {
  tiny: 'sm',
  small: 'sm',
  medium: 'md',
} as const;

export interface ButtonProps {
  variant?: keyof typeof VARIANT;
  color?: keyof typeof COLOR;
  size?: keyof typeof SIZE;
  text?: string;
  loadingText?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  variant = 'solid',
  color = 'primary',
  size = 'medium',
  text,
  loadingText,
  isLoading,
  onClick,
  ...rest
}, ref) => {
  return (
    <ChakraUI.Button
      variant={VARIANT[variant]}
      colorScheme={COLOR[color]}
      size={SIZE[size]}
      isLoading={isLoading}
      loadingText={loadingText}
      spinnerPlacement="end"
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      {text}
    </ChakraUI.Button>
  );
});

export default Button;
